
import { defineComponent, ref, PropType, computed, onMounted } from "vue";

import Utilities, { UtilitiesModel } from "@/assets/js/utilities";
import useHelpText from "@/assets/js/useHelpText";

import QuizStepModel from "./QuizStepModel";
import SliderModel from "@/modules/sliders/slider/SliderModel";
import HelpTextGroupModel from "@/assets/js/HelpTextModel";

export default defineComponent({
	name: "QuizStepInput",
	emits: ["update:modelValue"],
	props: {
		quizStep: {
			type: Object as PropType<QuizStepModel>,
			required: true
		},
		quizStepOption: {
			type: Number,
			required: true
		},
		modelValue: {
			type: Object as PropType<Number | Number[]>,
			required: true
		},
		slider: {
			type: Object as PropType<SliderModel>,
			required: true
		},
		helpTextGroup: {
			type: Object as PropType<HelpTextGroupModel>,
			required: true
		}
	},
	setup(props, context) {
		const { setSliderHelpTextVal } = useHelpText();

		const mainText = ref();
		const subText = ref();

		const quizStepInputValue = computed({
			get: () => props.modelValue,
			set: (value) => {
				const currentValue = props.modelValue as Number[];
				if (Array.isArray(value) && value.length > 2 && Array.isArray(props.modelValue) && props.modelValue.length) {
					value = value.filter((x) => !currentValue.includes(x));
				}

				context.emit("update:modelValue", value);
			}
		});

		onMounted(() => {
			const helpTextVal = setSliderHelpTextVal(props.quizStepOption, props.slider.options.max!);

			if (props.quizStep.input?.mainText === "optionValue") {
				if (props.quizStep.input.format)
					// toString() is to appease TS
					mainText.value = Utilities[props.quizStep.input.format as keyof UtilitiesModel](props.quizStepOption.toString());
				else {
					mainText.value = props.quizStepOption;
				}
			} else {
				mainText.value = props.helpTextGroup.values[helpTextVal][props.quizStep.input!.mainText];
			}

			if (props.quizStep.input?.subText) subText.value = props.helpTextGroup.values[helpTextVal][props.quizStep.input.subText];
		});

		return {
			quizStepInputValue,
			mainText,
			subText
		};
	}
});
