
import { computed, defineComponent, PropType, ref } from "vue";

import useEmitter from "@/assets/js/useEmitter";

export default defineComponent({
	name: "CarListSort",
	emits: ["sliderSortChanged", "update:modelValue"],
	props: {
		modelValue: {
			type: [String] as PropType<string>
		},
		dense: {
			type: Boolean,
			default: true
		}
	},
	setup(props, context) {
		const emitter = useEmitter();

		const carListSort = computed({
			get: () => {
				if (!props.modelValue) return "bestmatch";
				return props.modelValue;
			},
			set: (value) => {
				context.emit("update:modelValue", value);
				emitter.emit("sliderSortChanged", value);
			}
		});

		const sortOptions = [
			{ value: "bestmatch", label: "Best Match" },
			{ value: "efficiencyhigh", label: "Efficiency: High" },
			// { value: "efficiencylow", label: "Efficiency: Low"},
			{ value: "performancehigh", label: "Performance: High" },
			// { value: "performancelow", label: "Performance: Low"},
			{ value: "pricehigh", label: "Price: High" },
			{ value: "pricelow", label: "Price: Low" },
			{ value: "qualityhigh", label: "Quality: High" },
			// { value: "qualitylow", label: "Quality: Low"},
			{ value: "sizebig", label: "Size: Biggest" },
			{ value: "sizesmall", label: "Size: Smallest" }
		];

		return {
			carListSort,
			sortOptions
		};
	}
});
