
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import ValuesSvc from "@/assets/js/valuesSvc";
import useViewport from "@/assets/js/useViewport";
import useEmitter from "@/assets/js/useEmitter";
import useScroll from "@/assets/js/useScroll";
import useSliders from "../sliders/useSliders";

import CenteredLayout from "LayoutComponents/layouts/CenteredLayout.vue";

import QuizStepModel, { QuizStepInputSelectedModel } from "./quizStep/QuizStepModel";
import { SliderValueMap, SliderValuesModel, SliderRangeValueType } from "../sliders/slider/SliderModel";
import { AdditionalOptionsSelectedModel } from "../sliders/additionalOptions/AdditionalOptionsModel";

import QuizStep from "./quizStep/QuizStep.vue";

export default {
	name: "Quiz",
	components: {
		CenteredLayout,
		QuizStep
	},
	setup() {
		const emitter = useEmitter();
		const router = useRouter();
		const viewport = useViewport();
		const sliders = useSliders();
		const scroll = useScroll();

		const currentStep = ref(1);
		const quizSteps = ref<QuizStepModel[]>([]);
		const quizSliderValues = ref<SliderValuesModel>({
			efficiency: null,
			performance: null,
			quality: null,
			size: {
				max: null,
				min: null
			},
			price: null
		});
		const quizOptions = ref<AdditionalOptionsSelectedModel>({});
		const quizSort = ref("bestmatch");

		const allStepsComplete = computed((): boolean => {
			// TODO: this should be allREQUIREDStepsComplete (options are not required)
			// workaround for now: only the number of sliders are 'required'
			return calcNextStep() > ValuesSvc.values.sliders.length;
		});

		const nextStep = function () {
			updateQuizStep(currentStep.value + 1);
		};
		const previousStep = function () {
			updateQuizStep(currentStep.value - 1);
		};

		const quizStepChange = function (input: QuizStepInputSelectedModel) {
			if (typeof input.val === "number") {
				quizSliderValues.value[input.name] = input.val;
			} else {
				const value = quizSliderValues.value[input.name] as SliderRangeValueType;
				value!.min = Math.min(...input.val);
				value!.max = input.val.length > 1 ? Math.max(...input.val) : null; // calcNextStep expects `null`
			}

			// Multi-select steps are checkboxes, which come through as arrays
			if (Array.isArray(input.val) && input.val.length < 2) return;

			setTimeout(function () {
				updateQuizStep();
			}, 250);
		};

		const calcNextStep = function () {
			let stepCount = 1;

			for (let i = 0; i < quizSteps.value.length; i++) {
				const quizStep: QuizStepModel = quizSteps.value[i];

				const sliderValue = quizSliderValues.value[quizStep.name] as SliderValueMap;

				// `undefined` is for Options step
				if (sliderValue === undefined || sliderValue === null || sliderValue.min === null || sliderValue.max === null) {
					break;
				}

				stepCount++;
			}

			return stepCount;
		};

		const updateQuizStep = function (step?: number) {
			currentStep.value = step || calcNextStep();

			if (viewport.smallScreens) {
				scroll.scrollContainer({
					elementId: "quizContainer"
				});
			}
		};
		const updateQuizOptions = function (options: AdditionalOptionsSelectedModel) {
			quizOptions.value = options;
		};
		const updateQuizSort = function (sort: string) {
			quizSort.value = sort;
		};
		const submitQuiz = function () {
			// TODO TRUCK: this will need to change for truck site... maybe throw an event for a parent component to submit?
			sliders.saveSlidersConfig({
				sliderValues: quizSliderValues.value,
				options: quizOptions.value,
				sort: quizSort.value
			});

			router.push({ name: "Matched-Cars" });
		};

		onMounted(() => {
			quizSteps.value = ValuesSvc.values.quizSteps;

			emitter.on("quizStepChange", quizStepChange);
			emitter.on("sliderOptionsChanged", updateQuizOptions);
			emitter.on("sliderSortChanged", updateQuizSort);
		});

		onUnmounted(() => {
			emitter.off("quizStepChange", quizStepChange);
			emitter.off("sliderOptionsChanged", updateQuizOptions);
			emitter.off("sliderSortChanged", updateQuizSort);
		});

		return {
			quizSteps,
			updateQuizStep,
			quizStepChange,
			currentStep,
			nextStep,
			previousStep,
			allStepsComplete,
			submitQuiz
		};
	}
};
