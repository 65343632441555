
import { defineComponent } from "vue";

import SidebarLayout from "LayoutComponents/layouts/SidebarLayout.vue";
import SideNav from "../layout/nav/sideNav/SideNav.vue";

export default defineComponent({
	name: "Contact",
	components: {
		SidebarLayout,
		SideNav
	}
});
