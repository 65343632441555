
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	name: "InputActionsBar",
	emits: ["update:modelValue"],
	props: {
		modelValue: {
			type: String,
			required: true
		},
		inputActions: {
			type: Object as PropType<{ label: string; value: string }>,
			required: true
		}
	},
	setup(props, context) {
		const selection = computed({
			get: () => props.modelValue,
			set: (value) => context.emit("update:modelValue", value)
		});

		return { selection };
	}
});
