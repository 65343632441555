
import { defineComponent } from "vue";

import Nav from "LayoutComponents/nav/Nav.vue";

export default defineComponent({
	name: "Header",
	components: {
		Nav
	}
});
