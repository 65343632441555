
import { defineComponent, PropType } from "vue";

import CarModel from "@/modules/carDisplay/CarModel";

// Ex: /Similar-Cars/Acura-ZDX-Base
export default defineComponent({
	name: "OutOfProductionWarning",
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		}
	}
});
