import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_fab = _resolveComponent("q-fab")!

  return (_ctx.showDrawerToggleFloater)
    ? (_openBlock(), _createBlock(_component_q_fab, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        class: "drawerToggleFloater",
        fab: "",
        color: "primary",
        icon: _ctx.iconName,
        "active-icon": _ctx.iconName,
        size: "lg",
        label: _ctx.label,
        "label-class": "fabActionLabelClass"
      }, null, 8, ["icon", "active-icon", "label"]))
    : _createCommentVNode("", true)
}