
import { computed, defineComponent, onMounted, onUnmounted, PropType, provide, ref } from "vue";

import useEmitter from "@/assets/js/useEmitter";
import useViewport from "@/assets/js/useViewport";

import DrawerToggleFloaterConfigModel from "@/modules/layout/layouts/DrawerToggleFloaterConfigModel";

import DrawerToggleFloater from "LayoutComponents/layouts/DrawerToggleFloater.vue";

// Loading is embedded in page layouts because in order to look right, it needs to be between <q-page-container /> and <q-drawer /> & <q-page />

export default defineComponent({
	name: "SidebarLayout",
	components: {
		DrawerToggleFloater
	},
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		drawerToggleFloaterConfig: {
			type: Object as PropType<DrawerToggleFloaterConfigModel>
		}
	},
	setup() {
		// TODO: try starting in mobile, expanding the drawer, then resizing the window bigger... it doesn't auto-close the drawer
		// TODO: between 791-799 viewport width both the drawer and the fab button show for some reason

		const emitter = useEmitter();
		const viewport = useViewport();

		const drawerOpen = ref(viewport.largeScreens);

		const drawerShownBreakpoint = viewport.breakpoints.medium;
		provide("drawerShownBreakpoint", drawerShownBreakpoint);

		const contentPaddingClasses = computed(() => {
			if (viewport.largeScreens) return "q-px-xl q-py-lg";
			if (viewport.medium) return "q-pa-lg";

			return "q-pa-md";
		});

		const drawerWidth = computed((): number => {
			if (viewport.width < 400) return viewport.width;

			return 400;
		});

		function toggleDrawer() {
			drawerOpen.value = !drawerOpen.value;

			// TODO: reenable this scroll
			// document.getElementById("leftCol").scrollTop = 0;
		}

		onMounted(() => {
			emitter.on("toggleDrawer", toggleDrawer);
		});

		onUnmounted(() => {
			emitter.off("toggleDrawer", toggleDrawer);
		});

		return { drawerOpen, drawerWidth, drawerShownBreakpoint, contentPaddingClasses, viewport, toggleDrawer };
	}
});
