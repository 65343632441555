<template>
	<div class="row no-wrap">
		<nav v-if="linkTest.shown.length">
			<template v-for="(navLink, index) in linkTest.shown" :key="index">
				<component v-if="navLink.component" :is="navLink.component" :classes="`navAction linkTextCTA`" />
				<router-link v-else :to="navLink.to" class="navAction linkTextCTA" :title="navLink.toolTip">
					<span>{{ navLink.title }}</span>
				</router-link>
			</template>
		</nav>

		<div class="q-ml-sm" v-if="linkTest.menu.length">
			<q-btn icon="menu" flat round size="md" class="text-dark">
				<q-menu fit>
					<q-list style="min-width: 100px">
						<template v-for="(navLink, index) in linkTest.menu" :key="index">
							<q-item clickable v-close-popup>
								<q-item-section>
									<component v-if="navLink.component" :is="navLink.component" :classes="`linkTextCTA`" />
									<router-link v-else :to="navLink.to" class="linkTextCTA">
										<span>{{ navLink.title }}</span>
									</router-link>
								</q-item-section>
							</q-item>
							<q-separator v-if="index !== linkTest.menu.length - 1" />
						</template>
					</q-list>
				</q-menu>
			</q-btn>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed } from "vue";

import useViewport from "@/assets/js/useViewport";

export default defineComponent({
	name: "Nav",
	props: {
		showAll: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		const viewport = useViewport();

		const navLinks = [
			{
				to: { name: "Quiz" },
				toolTip: "Take a fast and easy quiz to find the right car",
				title: "Quiz"
			},
			{
				to: { name: "Matched-Cars" },
				toolTip: "Use the sliders to find the right car",
				title: "Sliders"
			},
			{
				to: { name: "SimilarCarsSelect" },
				toolTip: "Find similar cars based on a car you already know",
				title: "Similar"
			},
			{
				to: { name: "SuggestForMeSelect" },
				toolTip: "Not happy with your current car? Get suggestions to help you start",
				title: "Suggest"
			},
			{
				to: { name: "DetailSelect" },
				toolTip: "Research an individual car",
				title: "Search"
			},
			// {
			// 	to: "",
			// 	disabled: "disabled",
			// 	class: "navAction findATruckNavAction linkTextCTA",
			// 	toolTip: "Coming Soon!",
			// 	title: "Find a Truck"
			// },
			{
				to: { name: "Saved-Cars" },
				toolTip: "View my saved cars.",
				title: "Saved"
			},
			{
				to: { name: "About" },
				title: "About"
			},
			{
				to: { name: "Contact" },
				title: "Contact"
			}
		];

		const context = computed(() => {
			if (viewport.max) return 6;
			if (viewport.large || viewport.extraLarge) return 6;
			if (viewport.medium) return 4;
			if (viewport.small) return 2;

			return 0;
		});

		const linkTest = computed(() => {
			if (props.showAll) {
				return {
					shown: navLinks,
					menu: []
				};
			}

			return {
				shown: navLinks.slice(0, context.value),
				menu: navLinks.slice(context.value)
			};
		});

		return { linkTest };
	}
});
</script>

<style lang="scss">
nav {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
}
</style>
