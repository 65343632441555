interface ScrollModel {
	elementId?: string;
	offset?: number;
}

export default function useScroll() {
	const scrollContainer = (scrollConfig: ScrollModel) => {
		const container = scrollConfig.elementId ? document.getElementById(scrollConfig.elementId) : document.getElementById("sidebarLayoutPageContent");
		const offset = scrollConfig.offset || 15; // not 16 because FF shifts a pixel when scrolling stops

		const top = container!.getBoundingClientRect().top + window.scrollY;

		// 65 is header height... there might be a quasar variable for this
		window.scroll({
			top: top - 65 - offset,
			left: 0,
			behavior: "smooth"
		});
	};

	return {
		scrollContainer
	};
}
