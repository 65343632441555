
import { computed, defineComponent, ref } from "vue";

import HomeTabModel from "./HomeTabModel";

import FindCarsMatched from "../../findCars/FindCars.Matched.vue";
import FindCars from "../../findCars/FindCars.vue";

const homeTabsMetadata = require("./homeTabsMetadata.json");

export default defineComponent({
	name: "HomeTabs",
	components: {
		FindCarsMatched,
		FindCars
	},
	setup() {
		const activeHomeTab = ref("matched");

		const activeComponent = computed(() => {
			return homeTabsMetadata.find((tab: HomeTabModel) => tab.name === activeHomeTab.value)?.component;
		});

		const selectTab = (tabName: string) => {
			activeHomeTab.value = tabName;
		};

		return {
			homeTabsMetadata,
			activeHomeTab,
			activeComponent,
			selectTab
		};
	}
});
