import { AxiosRequestConfig } from "axios";
import { Ref, ref } from "vue";
import Dialog from 'quasar/src/plugins/Dialog.js';;

import axiosInstance from "CarsJSAssets/useAxios";

export default function useApiSvc<T>(endpoint: string): {
	loading: Ref<boolean>;
	getList: (config?: AxiosRequestConfig) => Promise<T[]>;
} {
	const loading = ref<boolean>(false);

	function qAlert(error: any) {
		Dialog.create({
			title: "Alert",
			message: error.message
		});
	}

	async function getList(config?: AxiosRequestConfig): Promise<T[]> {
		loading.value = true;

		// https://github.com/axios/axios/issues/5579
		// let list;
		// try {
		// 	list = await axiosInstance.get(endpoint, config);
		// } catch (error: any) {
		// 	console.log(`ERROR: ${error}`);
		// 	// I've never seen this return the error from the server like it should
		// 	throw error;
		// } finally {
		// 	loading.value = false;
		// }
		// return list.data;

		return axiosInstance
			.get(endpoint, config)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				qAlert(error.response.data);
				throw error.response.data;
			})
			.finally(() => {
				loading.value = false;
			});
	}

	return {
		loading,
		getList
	};
}
