
import { defineComponent, PropType } from "vue";

import QuizStepModel from "./QuizStepModel";

import QuizStepInputs from "./QuizStepInputs.vue";
import QuizStepOptions from "./QuizStepOptions.vue";

export default defineComponent({
	name: "QuizStep",
	components: {
		QuizStepInputs,
		QuizStepOptions
	},
	props: {
		quizStep: {
			type: Object as PropType<QuizStepModel>,
			required: true
		}
	}
});
