import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pageLayout detail" }
const _hoisted_2 = {
  key: 0,
  class: "selectedCarContainer"
}
const _hoisted_3 = { class: "detailHeaderMain siteWidth" }
const _hoisted_4 = { class: "titleContainer" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "contentContainer detailHeaderMainContentContainer" }
const _hoisted_7 = { class: "detailHeaderContentRight" }
const _hoisted_8 = { class: "detailCarContent siteWidth" }
const _hoisted_9 = { class: "adSpaceContainer siteWidth" }
const _hoisted_10 = { class: "thumbnailContainer" }
const _hoisted_11 = { class: "actionsContainer" }
const _hoisted_12 = { class: "availableTrimsContent" }
const _hoisted_13 = { class: "text" }
const _hoisted_14 = {
  key: 1,
  class: "text"
}
const _hoisted_15 = { class: "specsContent" }
const _hoisted_16 = { class: "adSpaceContainer siteWidth" }
const _hoisted_17 = { class: "similarCarsContent" }
const _hoisted_18 = { class: "actionsContainer detailActionsContainer" }
const _hoisted_19 = { class: "suggestedCarsContent" }
const _hoisted_20 = { class: "formContainer findCarsFormContainer findSearchCarsFormContainer siteWidth-Inner" }
const _hoisted_21 = { class: "formElements findCarsFormElements findSearchCarsFormElements" }
const _hoisted_22 = { class: "actionsContainer findCarsActionsContainer findCarActionsContainer" }
const _hoisted_23 = { class: "adSpaceContainer siteWidth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OutOfProductionWarning = _resolveComponent("OutOfProductionWarning")!
  const _component_RadarChart = _resolveComponent("RadarChart")!
  const _component_Ratings = _resolveComponent("Ratings")!
  const _component_SearchLocal = _resolveComponent("SearchLocal")!
  const _component_AdsenseAd = _resolveComponent("AdsenseAd")!
  const _component_OpenGallery = _resolveComponent("OpenGallery")!
  const _component_DetailSection = _resolveComponent("DetailSection")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SpecsSection = _resolveComponent("SpecsSection")!
  const _component_SuggestedCarContainer = _resolveComponent("SuggestedCarContainer")!
  const _component_SelectMakeModel = _resolveComponent("SelectMakeModel")!
  const _component_ContentLayout = _resolveComponent("ContentLayout")!

  return (_openBlock(), _createBlock(_component_ContentLayout, {
    class: "bg-white",
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.selectedCar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["detailHeader q-pt-lg", { noImage: !_ctx.selectedCar.images.gallery_path }])
              }, [
                (!_ctx.selectedCar.in_production)
                  ? (_openBlock(), _createBlock(_component_OutOfProductionWarning, {
                      key: 0,
                      car: _ctx.selectedCar
                    }, null, 8, ["car"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h1", _hoisted_5, [
                      _createTextVNode(" Is " + _toDisplayString(_ctx.Utilities.article(_ctx.selectedCar.name)) + " ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.selectedCar.name), 1),
                      _createTextVNode(" Right For Me? ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(), _createBlock(_component_RadarChart, {
                      car: _ctx.selectedCar,
                      key: _ctx.$route.fullPath
                    }, null, 8, ["car"])),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_Ratings, {
                        car: _ctx.selectedCar,
                        layout: "vertical"
                      }, null, 8, ["car"]),
                      _createVNode(_component_SearchLocal, { car: _ctx.selectedCar }, null, 8, ["car"])
                    ])
                  ])
                ]),
                (_ctx.selectedCar.images.gallery_path)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "detailHeaderImage",
                      style: _normalizeStyle({ backgroundImage: _ctx.headerBGImageUrl })
                    }, null, 4))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_AdsenseAd, { dataAdSlot: "9423361275" })
                ]),
                (_ctx.selectedCar.images.gallery_paths.length > 1)
                  ? (_openBlock(), _createBlock(_component_DetailSection, {
                      key: 0,
                      title: "Images"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCar.images.gallery_paths.slice(0, 6), (image, index) => {
                            return (_openBlock(), _createBlock(_component_OpenGallery, {
                              car: _ctx.selectedCar,
                              openSlide: index,
                              key: image,
                              src: image
                            }, null, 8, ["car", "openSlide", "src"]))
                          }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_OpenGallery, {
                            car: _ctx.selectedCar,
                            mode: _ctx.OpenGalleryModes.TextLink
                          }, null, 8, ["car", "mode"])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.availableTrims.length > 1)
                  ? (_openBlock(), _createBlock(_component_DetailSection, {
                      key: 1,
                      title: "Available Trims"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTrims, (trim, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                              (trim !== _ctx.selectedCar.trim)
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 0,
                                    to: { name: 'DetailCar', params: _ctx.trimParams(trim) },
                                    title: `View more information on the ${_ctx.selectedCar.model} ${trim} trim!`
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_13, _toDisplayString(trim), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to", "title"]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(trim), 1))
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_DetailSection, { title: "Specs" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_SpecsSection, {
                        specsTitle: "Engine",
                        specsObject: _ctx.selectedCar.engine
                      }, null, 8, ["specsObject"]),
                      _createVNode(_component_SpecsSection, {
                        specsTitle: "Fuel",
                        specsObject: _ctx.selectedCar.fuel
                      }, null, 8, ["specsObject"]),
                      _createVNode(_component_SpecsSection, {
                        specsTitle: "Exterior",
                        specsObject: _ctx.selectedCar.exterior
                      }, null, 8, ["specsObject"]),
                      _createVNode(_component_SpecsSection, {
                        specsTitle: "Dimensions",
                        specsObject: _ctx.selectedCar.dimensions
                      }, null, 8, ["specsObject"]),
                      _createVNode(_component_SpecsSection, {
                        specsTitle: "Interior",
                        specsObject: _ctx.selectedCar.interior
                      }, null, 8, ["specsObject"]),
                      (_ctx.selectedCar.warranty)
                        ? (_openBlock(), _createBlock(_component_SpecsSection, {
                            key: 0,
                            specsTitle: "Warranty",
                            specsObject: _ctx.selectedCar.warranty
                          }, null, 8, ["specsObject"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_AdsenseAd, { dataAdSlot: "3272885897" })
                ]),
                _createVNode(_component_DetailSection, { title: "Similar Cars" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.similarCars.slice(0, 6), (similarCar) => {
                        return (_openBlock(), _createBlock(_component_SuggestedCarContainer, {
                          key: similarCar.id,
                          suggestedCar: similarCar
                        }, null, 8, ["suggestedCar"]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_router_link, {
                        to: { name: 'SimilarCarsResults', params: { carName: _ctx.fullCarUrlSlug } },
                        class: "similarCarsAction",
                        title: "Consider these similar cars for your search"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View All Similar Cars")
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DetailSection, { title: "Suggestions" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_SuggestedCarContainer, {
                        suggestedCar: _ctx.suggestedCars?.betterMPG,
                        title: "Better MPG"
                      }, null, 8, ["suggestedCar"]),
                      _createVNode(_component_SuggestedCarContainer, {
                        suggestedCar: _ctx.suggestedCars?.faster,
                        title: "Faster"
                      }, null, 8, ["suggestedCar"]),
                      _createVNode(_component_SuggestedCarContainer, {
                        suggestedCar: _ctx.suggestedCars?.bigger,
                        title: "Bigger"
                      }, null, 8, ["suggestedCar"]),
                      _createVNode(_component_SuggestedCarContainer, {
                        suggestedCar: _ctx.suggestedCars?.smaller,
                        title: "Smaller"
                      }, null, 8, ["suggestedCar"]),
                      _createVNode(_component_SuggestedCarContainer, {
                        suggestedCar: _ctx.suggestedCars?.cheaper,
                        title: "Cheaper"
                      }, null, 8, ["suggestedCar"]),
                      _createVNode(_component_SuggestedCarContainer, {
                        suggestedCar: _ctx.suggestedCars?.nicer,
                        title: "nicer"
                      }, null, 8, ["suggestedCar"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DetailSection, { title: "Select a Different Car" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_SelectMakeModel, {
                          onSelectMakeModel: _ctx.makeModelSelected,
                          preselectedCar: _ctx.selectedCar
                        }, null, 8, ["onSelectMakeModel", "preselectedCar"])
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("button", {
                          class: "gradient buttonLarge",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoCar && _ctx.gotoCar(...args)))
                        }, "View Car")
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_AdsenseAd, { dataAdSlot: "1661594785" })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}