export enum FilterOperatorsEnum {
	AtLeast = "atleast",
	AtMost = "atmost",
	Exactly = "exactly"
}

export default interface AdditionalOptionsModel {
	bodyStyles: string[];
	doors: number[];
	doorsOperators: FilterOperatorsEnum[];
	seats: number[];
	seatsOperators: FilterOperatorsEnum[];
	engineTypes: string[];
	cylinders: number[];
	cylindersOperators: FilterOperatorsEnum[];
	drivetrains: string[];
	towingCapacities: number[];
	towingCapacityOperators: FilterOperatorsEnum[];
	makes: string[];
	countries: string[];
}

export interface AdditionalOptionsSelectedModel {
	bodyStyle?: string | null;
	doors?: number | null;
	doorsOperator?: string | null;
	seats?: number | null;
	seatsOperator?: string | null;
	engineType?: string | null;
	cylinders?: number | null;
	cylindersOperator?: string | null;
	drivetrain?: string | null;
	towingCapacity?: number | null;
	towingCapacityOperator?: string | null;
	make?: string | null;
	country?: string | null;
}
