import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "findCars" }
const _hoisted_2 = { class: "findCarsTop" }
const _hoisted_3 = { class: "findCarsTopContent" }
const _hoisted_4 = { class: "titleContainer findCarsTitleContainer" }
const _hoisted_5 = { class: "title findCarsTopTitle" }
const _hoisted_6 = {
  key: 0,
  class: "descriptionContainer"
}
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { class: "contentContainer findCarsContentContainer" }
const _hoisted_9 = { class: "formContainer findCarsFormContainer siteWidth-Inner" }
const _hoisted_10 = { class: "formElements findCarsFormElements" }
const _hoisted_11 = { class: "actionsContainer findCarsActionsContainer" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectMakeModel = _resolveComponent("SelectMakeModel")!
  const _component_QuickLinks = _resolveComponent("QuickLinks")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _renderSlot(_ctx.$slots, "formContent", {}, () => [
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(), _createBlock(_component_SelectMakeModel, {
              onSelectMakeModel: _ctx.makeModelSelected,
              key: _ctx.destination
            }, null, 8, ["onSelectMakeModel"]))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "gradient buttonLarge",
              disabled: !_ctx.selectMakeModel.trim,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.go && _ctx.go(...args)))
            }, _toDisplayString(_ctx.actionContent), 9, _hoisted_12)
          ])
        ])
      ]),
      (_ctx.showQuickLinks)
        ? (_openBlock(), _createBlock(_component_QuickLinks, {
            key: 0,
            destination: _ctx.destination
          }, null, 8, ["destination"]))
        : _createCommentVNode("", true)
    ])
  ]))
}