
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import CarsSvc from "@/assets/js/carsSvc";
import useRouteFunctions from "@Core/router/useRouteFunctions";
import Utilities from "@/assets/js/utilities";
import useEmitter from "@/assets/js/useEmitter";

import SelectMakeModel from "../selectMakeModel/SelectMakeModel.vue";
import CarModel from "../carDisplay/CarModel";
import SelectMakeModelModel from "../selectMakeModel/SelectMakeModelModel";

import SidebarLayout from "LayoutComponents/layouts/SidebarLayout.vue";

import CarList from "../carList/CarList.vue";
import CarDisplay from "../carDisplay/CarDisplay.vue";
import OutOfProductionWarning from "GeneralComponents/warnings/outOfProductionWarning/OutOfProductionWarning.vue";

// TODO: should I used slashes between make/model/year to make better use of vue params? also could use dashes in car names. might not be as good for seo though
// if so, set up a redirect from dashes to slashes

export default defineComponent({
	name: "SimilarCarsResults",
	components: {
		SidebarLayout,
		SelectMakeModel,
		CarList,
		CarDisplay,
		OutOfProductionWarning
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const routeFunctions = useRouteFunctions();
		const emitter = useEmitter();

		const loading = ref(true);
		const selectedCar = ref<CarModel>();
		const similarCars = ref<CarModel[]>();
		const selectMakeModel = ref<SelectMakeModelModel>({
			make: "",
			model: "",
			trim: ""
		});
		const showChangeSelectedCar = ref(false);

		const toggleLeftCol = () => {
			emitter.emit("toggleDrawer");
		};
		const onMakeModelSelected = (props: SelectMakeModelModel) => {
			selectMakeModel.value = props;
		};
		const gotoSimilarCars = () => {
			const carName = routeFunctions.buildFullCarUrlSlug(selectMakeModel.value);
			router.push({ name: "SimilarCarsResults", params: { carName: carName } });
		};
		const toggleChangeSelectedCar = () => {
			showChangeSelectedCar.value = !showChangeSelectedCar.value;
		};
		const setSelectedCar = (car: CarModel) => {
			car.selected = true; // child components use `selected`

			return car;
		};
		const updateTemplate = () => {
			let meta = {};

			if (selectedCar.value) {
				meta = routeFunctions.setMetaData({
					title: `Similar Cars Compared to ${Utilities.article(selectedCar.value.name)} ${selectedCar.value.name}`,
					description: `If you're considering ${Utilities.article(selectedCar.value.name)} ${
						selectedCar.value.name
					} for your next car, or just want to know what else to consider, take a look at these similar models.`,
					siteImgPath: selectedCar.value.images.gallery_path,
					twitterCardType: "summary_large_image"
				});
			}

			routeFunctions.updateMetaData({ meta }, null, null);
		};

		onMounted(async () => {
			const carName = route.params.carName;
			const responseArray = await Promise.all([CarsSvc.getCarByName(carName as string), CarsSvc.getSimilarCars(carName as string)]);

			selectedCar.value = setSelectedCar(responseArray[0]);
			similarCars.value = responseArray[1];

			updateTemplate();

			loading.value = false;
		});

		return {
			loading,
			Utilities,
			selectMakeModel,
			selectedCar,
			similarCars,
			showChangeSelectedCar,
			toggleChangeSelectedCar,
			onMakeModelSelected,
			toggleLeftCol,
			gotoSimilarCars
		};
	}
});
