import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createBlock(_component_Message, {
    centered: "",
    type: "warning",
    title: "Out of Production",
    text: `The <strong>${_ctx.car.name}</strong> is no longer in production, but here are some current cars to consider.`
  }, null, 8, ["text"]))
}