
import { computed, defineComponent, inject } from "vue";
import useViewport from "@/assets/js/useViewport";

export default defineComponent({
	name: "DrawerToggleFloater",
	emits: ["click"],
	props: {
		iconName: {
			type: String,
			default: "tune"
		},
		label: {
			type: String
		}
	},
	setup() {
		const viewport = useViewport();

		const drawerShownBreakpoint: number = inject("drawerShownBreakpoint", 0);

		const showDrawerToggleFloater = computed(() => viewport.width < drawerShownBreakpoint);

		return {
			showDrawerToggleFloater
		};
	}
});
