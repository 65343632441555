
import { computed, defineComponent, PropType } from "vue";
import { useRoute } from "vue-router";

import UserSvc from "@/assets/js/userSvc";
import useEmitter from "@/assets/js/useEmitter";
import useRouteFunctions from "@Core/router/useRouteFunctions";

import CarModel from "../CarModel";

export default defineComponent({
	name: "SecondaryLinks",
	emits: ["carSaved", "carUnsaved"],
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		}
	},
	setup(props) {
		const route = useRoute();
		const emitter = useEmitter();
		const routeFunctions = useRouteFunctions();

		const hideSimilarCarsLink = computed(() => {
			return route.name === "SimilarCarsResults" && props.car.selected;
		});
		const hideSuggestForMeLink = computed(() => {
			return route.name === "SuggestForMeResults" && props.car.selected;
		});
		const showSaveThisCarLink = computed(() => {
			return !props.car.saved && props.car.in_production;
		});
		const showSavedCarsLink = computed(() => {
			return props.car.saved && route.name !== "Saved-Cars";
		});
		const showUnsaveLink = computed(() => {
			return props.car.saved && route.name === "Saved-Cars";
		});
		const fullCarUrlSlug = computed(() => {
			return routeFunctions.buildFullCarUrlSlug(props.car);
		});

		function saveCar(carId: string) {
			props.car.saved = true;

			UserSvc.saveCar(carId);
			emitter.emit("carSaved", props.car);
		}
		function unsaveCar(carId: string) {
			UserSvc.unsaveCar(carId);
			emitter.emit("carUnsaved", props.car);
		}

		return {
			hideSimilarCarsLink,
			hideSuggestForMeLink,
			showSaveThisCarLink,
			showSavedCarsLink,
			showUnsaveLink,
			fullCarUrlSlug,
			saveCar,
			unsaveCar
		};
	}
});
