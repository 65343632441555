import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "specsSection" }
const _hoisted_2 = { class: "titleContainer" }
const _hoisted_3 = { class: "contentContainer" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = {
  key: 0,
  class: "value"
}
const _hoisted_6 = {
  key: 1,
  class: "colorsContainer"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = {
  key: 2,
  class: "cargoCapacityContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.specsTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specsObject, (value, name, index) => {
        return (_openBlock(), _createElementBlock("p", { key: index }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedName(name)) + ":", 1),
          (_ctx.regularSpecDisplay(name))
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.TextSvc.formatText(name, value)), 1))
            : _createCommentVNode("", true),
          (_ctx.colorsSpec(name))
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (color, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: "colorValue",
                    title: color.name,
                    style: _normalizeStyle({ backgroundColor: 'rgb(' + color.rgb + ')' })
                  }, null, 12, _hoisted_7))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.cargoCapacitySpec(name))
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (capacity, name, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: "capacityValue"
                  }, _toDisplayString(_ctx.formattedName(name)) + ": " + _toDisplayString(_ctx.TextSvc.formatText(name, capacity)), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}