
import { defineComponent } from "vue";

import Header from "LayoutComponents/header/Header.vue";

export default defineComponent({
	name: "SiteLayout",
	components: {
		Header
	}
});
