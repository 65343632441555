import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "optContainer" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuggestedCarDisplay = _resolveComponent("SuggestedCarDisplay")!
  const _component_CarDisplay = _resolveComponent("CarDisplay")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "suggestedCarContainer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openCarInfo && _ctx.openCarInfo(...args)), ["prevent"])),
      class: _normalizeClass([_ctx.$attrs.class])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.title) + ":", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_SuggestedCarDisplay, { suggestedCar: _ctx.suggestedCar }, null, 8, ["suggestedCar"])
      ])
    ], 2),
    (_ctx.suggestedCar.id)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          modelValue: _ctx.carDisplayDialog,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.carDisplayDialog) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CarDisplay, { car: _ctx.suggestedCar }, null, 8, ["car"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}