import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "homeTabs" }
const _hoisted_2 = { class: "homeTabsActionContainer" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { id: "homeContainer" }
const _hoisted_5 = { class: "homeContentSection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeTabsMetadata, (homeTab) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["buttonTextExceptActive", { active: _ctx.activeHomeTab === homeTab.name }]),
          key: homeTab.name,
          onClick: ($event: any) => (_ctx.selectTab(homeTab.name))
        }, [
          _createElementVNode("span", null, _toDisplayString(homeTab.title), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.activeComponent.destination)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent.name), {
              key: 0,
              title: _ctx.activeComponent.title,
              description: _ctx.activeComponent.description,
              destination: _ctx.activeComponent.destination,
              actionContent: _ctx.activeComponent.actionContent
            }, null, 8, ["title", "description", "destination", "actionContent"]))
          : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent.name), { key: 1 }))
      ])
    ])
  ]))
}