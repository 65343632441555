
import { defineComponent } from "vue";

import Nav from "../Nav.vue";

export default defineComponent({
	name: "SideNav",
	components: {
		Nav
	}
});
