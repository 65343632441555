
import { defineComponent, PropType, ref } from "vue";

import Utilities from "@/assets/js/utilities";

import CarModel from "@/modules/carDisplay/CarModel";

export default defineComponent({
	name: "SuggestedCarDisplay",
	props: {
		suggestedCar: {
			type: Object as PropType<CarModel>,
			required: true
		}
	},
	setup() {
		return {
			Utilities
		};
	}
});
