
import { defineComponent } from "vue";

import useRouteFunctions from "@Core/router/useRouteFunctions";

import CarModel from "../carDisplay/CarModel";

export default defineComponent({
	name: "QuickLinks",
	props: {
		destination: {
			type: String,
			required: true
		}
	},
	setup(props) {
		const routeFunctions = useRouteFunctions();

		const quickLinksSections = [
			{
				title: "Most Popular",
				cars: [
					{ make: "Honda", model: "Civic", trim: "Base" },
					{ make: "Toyota", model: "Camry", trim: "Base" },
					{ make: "Toyota", model: "Corolla", trim: "Base" },
					{ make: "Honda", model: "Accord", trim: "Base" },
					{ make: "Nissan", model: "Altima", trim: "Base" }
				]
			},
			{
				title: "Sports Cars",
				cars: [
					{ make: "Ford", model: "Mustang", trim: "Base" },
					{ make: "Chevrolet", model: "Camaro", trim: "Base" },
					{ make: "Dodge", model: "Challenger", trim: "Base" },
					{ make: "Chevrolet", model: "Corvette", trim: "Stingray" },
					{ make: "Porsche", model: "911", trim: "Carrera" }
				]
			},
			{
				title: "SUVs",
				cars: [
					{ make: "Nissan", model: "Rogue", trim: "Base" },
					{ make: "Honda", model: "CR-V", trim: "Base" },
					// { make: 'Toyota', model: 'RAV4', trim: 'Base' },
					// { make: 'Jeep', model: 'Grand Cherokee', trim: 'Base' },
					{ make: "Ford", model: "Escape", trim: "Base" },
					{ make: "Chevrolet", model: "Equinox", trim: "Base" }
				]
			},
			{
				title: "Sedans",
				cars: [
					{ make: "Honda", model: "Civic", trim: "Base" },
					{ make: "Toyota", model: "Camry", trim: "Base" },
					{ make: "Toyota", model: "Corolla", trim: "Base" },
					{ make: "Honda", model: "Accord", trim: "Base" },
					{ make: "Nissan", model: "Altima", trim: "Base" }
				]
			}
		];

		const carDisplayName = (car: CarModel) => {
			const trim = car.trim !== "Base" ? " " + car.trim : "";
			return car.make + " " + car.model + trim;
		};

		const carParams = (car: CarModel) => {
			return routeFunctions.buildURLParams(car, props.destination);
		};

		return {
			quickLinksSections,
			carDisplayName,
			carParams
		};
	}
});
