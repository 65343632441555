
import { defineComponent, PropType } from "vue";

// TODO: this registers the swiper components globally... it should probably live somewhere else
import { register } from "swiper/element/bundle";
register();

import CarModel from "../carDisplay/CarModel";

export default defineComponent({
	name: "Gallery",
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		},
		openSlide: {
			type: Number,
			default: 0
		}
	}
});
