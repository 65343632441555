
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "PageRoot",
	setup() {
		const route = useRoute();

		return { route };
	}
});
