import Utilities from "CarsJSAssets/utilities";

interface TextValuesModel {
	[key: string]: string;
}

const textValues: TextValuesModel = {
	// Units; Used on Details page
	first_row_down: "{/} sq ft",
	second_row_down: "{/} sq ft",
	trunk: "{/} sq ft",

	total_interior_volume: "{/} cu ft",
	passenger_volume: "{/} cu ft",

	payload: "{/} lbs",
	towing: "{/} lbs",

	horsepower: "{/} hp",
	torque: "{/} lb/ft",
	top_speed: "{/} mph",
	zero_to_sixty: "{/}s",

	gvwr: "{/} lbs",
	height: '{/}"',
	length: '{/}"',
	weight: "{/} lbs",
	width: '{/}"',

	fuel_range: "{/} miles",
	electric_range: "{/} miles",

	mpge: "{/} mpge",
	mpg_city: "{/} mpg",
	mpg_highway: "{/} mpg",
	mpg_combined: "{/} mpg",

	// Sliders
	efficiency_meaning: "At least <strong>{/}</strong> MPG Highway or MPGe.",
	performance_meaning: "0-60 <strong>{/}</strong> seconds or faster."
};

export default {
	formatText: function (...values: string[]) {
		const key = values[0];
		let text = textValues[key];

		if (!text) return values[1]; // Note this is returning the value, not the key

		const replaceValues = values.slice(1);

		for (let i = 0; i < replaceValues.length; i++) {
			text = text.replace("{/}", Utilities.addCommas(replaceValues[i])); // Not using regex only replaces one instance of {/} at a time
		}

		return text;
	}
};
