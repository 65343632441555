export interface UtilitiesModel {
	addCommas: (val: string) => string;
	currency: (val: string | number) => string;
	article: (val: string) => string;
	mpg: (val: string) => string;
}

const Utilities: UtilitiesModel = {
	addCommas: function (value: string): string {
		value += "";
		const x = value.split(".");
		let x1 = x[0];
		const x2 = x.length > 1 ? "." + x[1] : "";
		const rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
			x1 = x1.replace(rgx, "$1" + "," + "$2");
		}
		return x1 + x2;
	},
	currency: function (value: string | number): string {
		value = +value;

		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0
		});

		return formatter.format(value);
	},
	article: function (value: string): string {
		if (!value) return ""; // TODO: not sure if I need this guard

		const $carName = value.toString();

		const $carNameFirstChar = $carName.charAt(0).toUpperCase();
		const $vowels = ["A", "E", "I", "O", "U"];

		return $vowels.includes($carNameFirstChar) ? "an" : "a";
	},
	mpg: function (value: string): string {
		return value + " MPG";
	}
};

export default Utilities;
