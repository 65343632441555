import {
	NavigationGuardNext,
	RouteLocationNormalized,
	RouteMeta,
	RouteParamsRaw,
	RouteRecordRaw
} from "../../../admin/node_modules/vue-router/dist/vue-router"; // TODO: this needs be dynamic referring to the app that's currently using it

import CarModel from "../../../whichCar/src/modules/carDisplay/CarModel";
import SelectMakeModelModel from "../../../whichCar/src/modules/selectMakeModel/SelectMakeModelModel";
import { MetaOnlyModel } from "../../../whichCar/src/router/RouteMetaModels";

// import { ProcessEnv } from "vue";

// interface Environment {
// 	VUE_APP_SITE_NAME: string;
// }
interface ProcessEnv {
	[key: string]: string | undefined;
}

export default function useRouteFunctions(env?: ProcessEnv) {
	const buildURLParams = (car: CarModel | SelectMakeModelModel, destination: string) => {
		// TODO: this function won't be necessary once all urls follow the same pattern
		if (destination === "DetailCar") {
			return {
				make: encodeSlugForUrl(car.make as string),
				model: encodeSlugForUrl(car.model as string),
				trim: encodeSlugForUrl(car.trim as string)
			};
		}

		return {
			carName: buildFullCarUrlSlug(car as CarModel)
		};
	};

	const buildFullCarUrlSlug = (car: CarModel | RouteParamsRaw) => {
		return `${encodeSlugForUrl(car.make as string)}-${encodeSlugForUrl(car.model as string)}-${encodeSlugForUrl(car.trim as string)}`;
	};

	const decodeSlugFromUrl = (slug: string) => {
		// There's a PHP version of this function
		if (!slug || slug == "Base") return "";

		slug = slug.replace(/~/g, "-");
		slug = slug.replace(/_/g, " ");
		slug = slug.replace(/,/g, ".");

		return slug;
	};

	const encodeSlugForUrl = (slug: string | undefined) => {
		// There's a PHP version of this function
		if (!slug || slug == "Base") return "Base";

		slug = slug.replace(/-/g, "~");
		slug = slug.replace(/\s/g, "_");
		slug = slug.replace(/\./g, ",");

		return slug;
	};

	const setMetaData = (meta: RouteMeta): RouteMeta => {
		// Meta Defaults
		const siteName = env?.VUE_APP_SITE_NAME || "Which Car is Right For Me";
		const siteBaseUrl = "http://" + window.location.hostname; // window.location.href?
		const siteImgPath = meta.siteImgPath || "/assets/images/logo/wcirfm_mark-square.png";
		const siteLogoMark = siteBaseUrl + siteImgPath;

		const twitterCardType = meta.twitterCardType || "summary";

		const pageURL = window.location.href; // siteBaseUrl + $_SERVER['REQUEST_URI'];
		const pageTitle = meta.title || "Find the Right Car";
		const pageTitleAppend = ` | ${siteName}`;
		const pageImage = siteLogoMark;

		// TODO: add og:image:width and og:image:height already used in index.html file

		return {
			title: pageTitle + pageTitleAppend,
			content_title: pageTitle,
			metaTags: [
				{ name: "description", content: meta.description as string },

				// facebook open graph tags
				{ property: "og:type", content: "website" },
				{ property: "og:site_name", content: siteName },
				{ property: "og:url", content: pageURL },
				{ property: "og:title", content: pageTitle },
				{ property: "og:description", content: meta.description as string },
				{ property: "og:image", content: pageImage },

				// twitter card tags
				{ name: "twitter:card", content: twitterCardType },
				{ name: "twitter:url", content: pageURL },
				{ name: "twitter:title", content: pageTitle },
				{ name: "twitter:description", content: meta.description as string },
				{ name: "twitter:image", content: pageImage }
			]
		};
	};

	const updateMetaData = (to: RouteLocationNormalized | MetaOnlyModel, from: RouteLocationNormalized | null, next: NavigationGuardNext | null) => {
		// This goes through the matched routes from last to first, finding the closest route with a title.
		// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.

		const nearestWithTitle = to.matched
			? to.matched
					.slice()
					.reverse()
					.find((r: RouteRecordRaw) => r.meta?.title)
			: to;

		const nearestWithMeta = to.matched
			? to.matched
					.slice()
					.reverse()
					.find((r: RouteRecordRaw) => r.meta?.metaTags)
			: to;

		if (nearestWithTitle) document.title = nearestWithTitle.meta.title as string;

		Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode?.removeChild(el));

		if (!nearestWithMeta && next) return next();

		if (nearestWithMeta?.meta.metaTags?.length) {
			nearestWithMeta.meta.metaTags
				.map((tagDef: any) => {
					const tag = document.createElement("meta");

					Object.keys(tagDef).forEach((key) => {
						tag.setAttribute(key, tagDef[key]);
					});

					tag.setAttribute("data-vue-router-controlled", "");

					return tag;
				})
				.forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));
		}

		if (next) return next();
	};

	return {
		buildURLParams,
		buildFullCarUrlSlug,
		decodeSlugFromUrl,
		encodeSlugForUrl,
		setMetaData,
		updateMetaData
	};
}
