/**
 * All viewport breakpoint units in pixels
 */
interface BreakpointsModel {
	extraSmall: number;
	small: number;
	medium: number;
	large: number;
	extraLarge: number;
	max: number;
}

const breakpoints: BreakpointsModel = {
	extraSmall: 360,
	small: 480,
	medium: 800,
	large: 1200,
	extraLarge: 1440,
	max: 1920
};

export default class Viewport {
	screen;
	breakpoints = breakpoints;

	constructor(Screen: any) {
		Screen.setSizes({ sm: breakpoints.small, md: breakpoints.medium, lg: breakpoints.large, xl: breakpoints.extraLarge });
		this.screen = Screen;
	}

	get width() {
		return this.screen.width;
	}
	get height() {
		return this.screen.height;
	}

	/**
	 * Any viewport width smaller than `small`
	 */
	get extraSmall() {
		return this.width < this.breakpoints.extraSmall;
	}
	get small() {
		return this.width >= this.breakpoints.extraSmall && this.width < this.breakpoints.small;
	}
	get medium() {
		return this.width >= this.breakpoints.small && this.width < this.breakpoints.medium;
	}
	get large() {
		return this.width >= this.breakpoints.medium && this.width < this.breakpoints.large;
	}
	get extraLarge() {
		return this.width >= this.breakpoints.large && this.width < this.breakpoints.extraLarge;
	}
	/**
	 * Any viewport width bigger than `extraLarge`
	 */
	get max() {
		return this.width >= this.breakpoints.extraLarge;
	}

	/**
	 * Medium and above viewport width
	 */
	get largeScreens() {
		return this.width >= this.breakpoints.medium;
	}
	/**
	 * Below medium viewport width
	 */
	get smallScreens() {
		return this.width < this.breakpoints.medium;
	}

	// TODO: Create greater than / less than properties
}
