
import { defineComponent } from "vue";

import Presets from "../../sliders/presets/SliderPresets.vue";

export default defineComponent({
	name: "NoMatches",
	components: {
		Presets
	}
});
