
import { defineComponent, PropType, ref } from "vue";

import ValuesSvc from "@/assets/js/valuesSvc";
import useEmitter from "@/assets/js/useEmitter";

import QuizStepModel from "./QuizStepModel";

import QuizStepInput from "./QuizStepInput.vue";

export default defineComponent({
	name: "QuizStepInputs",
	components: {
		QuizStepInput
	},
	emits: ["quizStepChange"],
	props: {
		quizStep: {
			type: Object as PropType<QuizStepModel>,
			required: true
		}
	},
	setup(props) {
		const emitter = useEmitter();

		const slider = ValuesSvc.findSlider(props.quizStep.name);
		const helpTextGroup = ValuesSvc.findHelpTextGroup(props.quizStep.name);

		const quizInputValue = ref<(number[] | number) | null>(props.quizStep.input.type === "checkbox" ? ([] as number[]) : null);
		const activeHoverClass = ref<number[]>([]);

		const inputChange = (_value: number) => {
			if (quizInputValue.value === null) return;

			emitter.emit("quizStepChange", {
				val: quizInputValue.value,
				name: props.quizStep.name
			});
		};

		const inputMouseover = (value: number | null) => {
			if (!Array.isArray(quizInputValue.value) || quizInputValue.value === null) return;

			if (value === null) return (activeHoverClass.value = []); // ensure highlight gets cleared when you mouseout

			if (quizInputValue.value.length === 1) {
				const firstInputValue = quizInputValue.value[0];
				const hoveredIndex = props.quizStep.options.findIndex((x: number) => x === value);
				const selectedIndex = props.quizStep.options.findIndex((x: number) => x === firstInputValue);

				activeHoverClass.value = [hoveredIndex, selectedIndex].sort((a, b) => {
					return a - b;
				});
			}
		};

		return {
			quizInputValue,
			activeHoverClass,
			inputChange,
			inputMouseover,
			slider,
			helpTextGroup
		};
	}
});
