
import { defineComponent } from "vue";

import SidebarLayout from "LayoutComponents/layouts/SidebarLayout.vue";
import SideNav from "LayoutComponents/nav/sideNav/SideNav.vue";

export default defineComponent({
	name: "About",
	components: {
		SidebarLayout,
		SideNav
	},
	setup() {
		return {
			currentYear: new Date().getFullYear()
		};
	}
});
