
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";

import CarModel from "../carDisplay/CarModel";
import { SlidersConfigModel, SliderValuesModel } from "../sliders/slider/SliderModel";
import { AdditionalOptionsSelectedModel } from "../sliders/additionalOptions/AdditionalOptionsModel";

import useApiSvc from "@/assets/js/useApiSvc";
import ValuesSvc from "@/assets/js/valuesSvc";
import Utilities from "@/assets/js/utilities";
import useEmitter from "@/assets/js/useEmitter";
import useScroll from "@/assets/js/useScroll";
import useSliders from "../sliders/useSliders";

import SidebarLayout from "LayoutComponents/layouts/SidebarLayout.vue";

import CarList from "../carList/CarList.vue";
import AdditionalOptions from "../sliders/additionalOptions/AdditionalOptions.vue";
import Sliders from "../sliders/Sliders.vue";
import SliderPresetModel from "../sliders/presets/SliderPresetModel";
import useViewport from "@/assets/js/useViewport";

export default defineComponent({
	name: "MatchedCars",
	components: {
		SidebarLayout,
		CarList,
		AdditionalOptions,
		Sliders
	},
	emits: ["toggleDrawer"],
	setup() {
		const emitter = useEmitter();
		const scroll = useScroll();
		const viewport = useViewport();
		const { getList, loading } = useApiSvc<CarModel>("/api/cars/");

		const mounted = ref(false); // prevent CARp flash before initial list is populated
		const matchedCarsFull = ref<CarModel[]>([]);
		const showSliders = ref(true);
		const optionsSelectedCount = ref(0);
		const sliders = useSliders();
		const localSlidersConfig = ref<SlidersConfigModel>(sliders.slidersConfig);

		const slidersConfig = computed(() => {
			return sliders.slidersConfig;
		});

		const toggleLeftCol = () => {
			emitter.emit("toggleDrawer");

			let timer = 0;
			if (viewport.smallScreens) timer = 225; // a bit more milliseconds than it takes the qDrawer to close and remove q-body--prevent-scroll from the body tag

			setTimeout(() => {
				scrollMainContainer();
			}, timer);
		};
		const toggleSliders = () => {
			showSliders.value = !showSliders.value;
		};
		const onShowSliders = () => {
			showSliders.value = true;
		};

		const scrollMainContainer = () => {
			scroll.scrollContainer({
				offset: 0
			});
		};
		const setMatchedCars = (cars: CarModel[]) => {
			matchedCarsFull.value = cars;

			updateOptionsCount(sliders.slidersConfig);
		};
		const updateOptionsCount = (sliderConfig: SlidersConfigModel) => {
			const operators = ["atleast", "exactly", "atmost"];
			let count = 0;

			for (let optionSelected in sliderConfig?.options) {
				const option = sliderConfig.options[optionSelected as keyof AdditionalOptionsSelectedModel];
				if (option && operators.indexOf(option as string) === -1) count++;
			}

			optionsSelectedCount.value = count;
		};

		const priceSlider = ValuesSvc.findSlider("price");
		const currentPriceText = () => {
			if (slidersConfig.value?.sliderValues.price === priceSlider?.options.max) return "for <strong>any price</strong>";

			let price = slidersConfig.value?.sliderValues.price || priceSlider?.options.value;

			return `starting under <strong>${Utilities.currency(price as number)}</strong> MSRP`;
		};
		const carListDescription = computed(() => {
			return `<strong>${matchedCarsFull.value.length}</strong> matching cars found ${currentPriceText()}.`;
		});

		const onPresetSelected = (preset: SliderPresetModel) => {
			if (!preset) return;

			localSlidersConfig.value.sliderValues = preset.sliderValues;
			sliders.saveSlidersConfig(localSlidersConfig.value);

			updateCarList();
		};

		const updateSliderRatings = (sliderValues: SliderValuesModel) => {
			if (!sliderValues) return;

			localSlidersConfig.value.sliderValues = sliderValues;
			sliders.saveSlidersConfig(localSlidersConfig.value);

			updateCarList();
		};
		const updateSliderOptions = (options: AdditionalOptionsSelectedModel) => {
			if (!options) return;

			localSlidersConfig.value.options = options;
			sliders.saveSlidersConfig(localSlidersConfig.value);

			// TODO: This doesn't need to call the server again, it can just filter the current list like the sort does
			updateCarList();
		};
		const updateSliderSort = (sort: string) => {
			localSlidersConfig.value.sort = sort;
			sliders.saveSlidersConfig(localSlidersConfig.value);
		};

		const getCars = async () => {
			const slidersConfig = sliders.viewSlidersConfig();
			// Can't just use slidersConfig because we need a single-level deep object to create URL params from
			const params = Object.assign({}, slidersConfig.sliderValues, slidersConfig.options, { sort: slidersConfig.sort });

			const cars = await getList({ params });

			setMatchedCars(cars);
		};
		const updateCarList = async () => {
			await getCars();

			if (viewport.largeScreens) scrollMainContainer();
		};

		onMounted(async () => {
			await getCars();

			emitter.on("presetSelected", onPresetSelected);
			emitter.on("slidersUpdated", updateSliderRatings);
			emitter.on("sliderOptionsChanged", updateSliderOptions);
			emitter.on("sliderSortChanged", updateSliderSort);
			emitter.on("showSliders", onShowSliders);

			mounted.value = true;
		});

		onUnmounted(() => {
			emitter.off("presetSelected", onPresetSelected);
			emitter.off("slidersUpdated", updateSliderRatings);
			emitter.off("sliderOptionsChanged", updateSliderOptions);
			emitter.off("sliderSortChanged", updateSliderSort);
			emitter.off("showSliders", onShowSliders);
		});

		return {
			loading,
			localSlidersConfig,
			mounted,
			matchedCarsFull,
			showSliders,
			optionsSelectedCount,
			toggleSliders,
			toggleLeftCol,
			carListDescription
		};
	}
});
