
// TODO: Rename this & related files "filters"

import { ref, onMounted, defineComponent, PropType, computed } from "vue";

import CarsSvc from "@/assets/js/carsSvc";
import useEmitter from "@/assets/js/useEmitter";

import AdditionalOptionsModel, { AdditionalOptionsSelectedModel, FilterOperatorsEnum } from "./AdditionalOptionsModel";

import InputActionsBar from "@/modules/layout/form/InputActionsBar.vue";

// TODO: Create the list of options dynamically? It's returned as an object, maybe it could be an array of objects instead?
// TODO: There's probably a way to create a component for the operators; pass in an array of options, then set them on the option when they get updated via props...

export default defineComponent({
	name: "AdditionalOptions",
	components: {
		InputActionsBar
	},
	emits: ["sliderOptionsChanged", "showSliders", "update:modelValue"],
	props: {
		modelValue: {
			type: Object as PropType<AdditionalOptionsSelectedModel>
		}
	},
	setup(props, context) {
		const emitter = useEmitter();

		const options = ref<AdditionalOptionsModel>({
			bodyStyles: [],
			doors: [],
			doorsOperators: [],
			seats: [],
			seatsOperators: [],
			engineTypes: [],
			cylinders: [],
			cylindersOperators: [],
			drivetrains: [],
			towingCapacities: [],
			towingCapacityOperators: [],
			makes: [],
			countries: []
		});
		const optionsSelected = computed({
			get: () => {
				if (!props.modelValue) return {};
				return props.modelValue;
			},
			set: (value) => {
				context.emit("update:modelValue", value);
			}
		});

		function inputChange(value?: number, inputModelName?: string) {
			if (!optionsSelected.value) return;

			// TODO: there's probably a pattern to be found here
			if (inputModelName === "doors" && value !== null) optionsSelected.value.doorsOperator = FilterOperatorsEnum.Exactly;
			else if (inputModelName === "doors" && value === null) optionsSelected.value.doorsOperator = undefined;

			if (inputModelName === "seats" && value !== null) optionsSelected.value.seatsOperator = FilterOperatorsEnum.AtLeast;
			else if (inputModelName === "seats" && value === null) optionsSelected.value.seatsOperator = undefined;

			if (inputModelName === "cylinders" && value !== null) optionsSelected.value.cylindersOperator = FilterOperatorsEnum.AtLeast;
			else if (inputModelName === "cylinders" && value === null) optionsSelected.value.cylindersOperator = undefined;

			if (inputModelName === "towingCapacity" && value !== null) optionsSelected.value.towingCapacityOperator = FilterOperatorsEnum.AtLeast;
			else if (inputModelName === "towingCapacity" && value === null) optionsSelected.value.towingCapacityOperator = undefined;

			emitter.emit("sliderOptionsChanged", optionsSelected.value);
		}
		function backToSliders() {
			emitter.emit("showSliders");
		}

		onMounted(async () => {
			// TODO STORE: use store so toggling between sliders & filters / quiz page desn't re-request data
			options.value = await CarsSvc.getCarOptions();
		});

		return {
			backToSliders,
			inputChange,
			options,
			optionsSelected
		};
	}
});
