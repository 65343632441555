import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingTypeBase = _resolveComponent("RatingTypeBase")!

  return (_openBlock(), _createBlock(_component_RatingTypeBase, {
    title: _ctx.slider.title,
    score: _ctx.car.scores[_ctx.slider.name]
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Made in "),
      _createElementVNode("strong", null, _toDisplayString(_ctx.car.details.country), 1)
    ]),
    _: 1
  }, 8, ["title", "score"]))
}