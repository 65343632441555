
import ValuesSvc from "@/assets/js/valuesSvc";
import useEmitter from "@/assets/js/useEmitter";
import useSliders from "./useSliders";

import Slider from "./slider/Slider.vue";
import { onMounted, onUnmounted } from "vue";

export default {
	name: "Sliders",
	components: {
		Slider
	},
	emits: ["slidersUpdated"],
	props: {
		mode: {
			type: String,
			default: "horizontal"
		}
	},
	setup() {
		const emitter = useEmitter();
		const sliders = useSliders();

		const onSliderUpdated = function () {
			// TODO: sending data off the carsSvc is unnecessary... everything is pulled from there anyway in the end
			emitter.emit("slidersUpdated", sliders.slidersConfig.sliderValues);
		};

		onMounted(() => {
			emitter.on("sliderUpdated", onSliderUpdated);
		});

		onUnmounted(() => {
			emitter.off("sliderUpdated", onSliderUpdated);
		});

		return {
			values: ValuesSvc.values
		};
	}
};
