import { createApp } from "vue";

import App from "./App.vue";

import { createWebHistory, createRouter } from "vue-router";
import routes from "@/router/routes";
export const router = createRouter({
	history: createWebHistory(),
	routes,
	linkActiveClass: "active",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			// This only works for the main window scroll
			// If resetting the scroll position doesn't seem like it's working, it's probably because the page scroll is actually on an inner container
			return { top: 0 };
		}
	}
});
import registerGlobalComponents from "@/router/globalComponents";
import useRouteFunctions from "@Core/router/useRouteFunctions";
const routeFunctions = useRouteFunctions();
// This callback runs before every route change, including on page load
router.beforeEach((to, from, next) => {
	return routeFunctions.updateMetaData(to, from, next);
});

import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { setupViewport } from "./assets/js/useViewport";

import VueGtag from "vue-gtag";
import ScriptX from "vue-scriptx";
import Ads from "vue-google-adsense";

import UserSvc from "CarsJSAssets/userSvc";
import ValuesSvc from "CarsJSAssets/valuesSvc";
import useSliders from "./modules/sliders/useSliders";
const sliders = useSliders();

async function loadApp() {
	// TODO: does the app actually need to wait for these?
	await ValuesSvc.getValues();
	await sliders.getSavedSlidersConfig();
	await UserSvc.getSavedCars();

	const app = createApp(App)
		.use(ScriptX)
		// @ts-ignore
		.use(Ads.Adsense)
		// @ts-ignore
		.use(Ads.InFeedAdsense)
		.use(Quasar, quasarUserOptions)
		.use(router)
		.use(
			VueGtag,
			{
				config: { id: "G-HJS4VN9H2D" }
			},
			router
		);

	registerGlobalComponents(app);
	setupViewport(app);

	app.mount("#app");
}

loadApp();
