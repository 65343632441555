import axios from "axios";
import Loading from 'quasar/src/plugins/Loading.js';;

const axiosInstance = axios.create({
	baseURL: "/", // TODO: using this I probably can remove the devServer api proxy in vueConfig
	headers: {
		"Content-type": "application/json",
		"Access-Control-Allow-Origin": "*"
	}
});

axiosInstance.interceptors.request.use(
	(config: any) => {
		Loading.show({
			delay: 500,
			spinnerColor: "primary"
		});

		return config;
	},
	(error: any) => {
		Loading.hide();

		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response: any) => {
		Loading.hide();

		return response;
	},
	(error: any) => {
		Loading.hide();

		return Promise.reject(error);
	}
);

export default axiosInstance;
