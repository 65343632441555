import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "carDisplayTop" }
const _hoisted_2 = { class: "carDisplayTopContent" }
const _hoisted_3 = { class: "titleContainer carDisplayTitleContainer" }
const _hoisted_4 = {
  key: 0,
  class: "carTags carTypeTags"
}
const _hoisted_5 = { class: "title carDisplayTitle" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "msrp" }
const _hoisted_8 = { class: "ratingsContainer" }
const _hoisted_9 = { class: "searchLocalContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SecondaryLinks = _resolveComponent("SecondaryLinks")!
  const _component_OpenGallery = _resolveComponent("OpenGallery")!
  const _component_Ratings = _resolveComponent("Ratings")!
  const _component_SearchLocal = _resolveComponent("SearchLocal")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), { ref: "carDisplay" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["carDisplay", [{ outOfProduction: !_ctx.car.in_production, carSaved: _ctx.isSaved }, _ctx.layout]])
      }, [
        (!_ctx.car.in_production)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 0,
              centered: "",
              type: "warning",
              text: `The <strong>${_ctx.car.name}</strong> is no longer in production.`
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.engineTag)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_q_icon, {
                      size: "md",
                      name: _ctx.engineTag.icon,
                      color: _ctx.engineTag.color
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Tooltip, {
                          color: _ctx.engineTag.color,
                          text: `${_ctx.car.engine.type} Vehicle`
                        }, null, 8, ["color", "text"])
                      ]),
                      _: 1
                    }, 8, ["name", "color"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("h3", _hoisted_5, [
                _createVNode(_component_router_link, {
                  class: "linkText",
                  to: { name: 'DetailCar', params: { make: _ctx.carLinkParams.make, model: _ctx.carLinkParams.model, trim: _ctx.carLinkParams.trim } },
                  title: 'Is ' + _ctx.toolTipArticle + ' ' + _ctx.car.name + ' right for me? Learn more here'
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.car.name), 1)
                  ]),
                  _: 1
                }, 8, ["to", "title"]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Utilities.currency(_ctx.car.price)) + " MSRP", 1)
              ])
            ]),
            _createVNode(_component_SecondaryLinks, { car: _ctx.car }, null, 8, ["car"])
          ]),
          (_ctx.car.images.thumbnail_path)
            ? (_openBlock(), _createBlock(_component_OpenGallery, {
                key: 0,
                car: _ctx.car
              }, null, 8, ["car"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Ratings, {
            car: _ctx.car,
            layout: _ctx.layout
          }, null, 8, ["car", "layout"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_SearchLocal, { car: _ctx.car }, null, 8, ["car"])
        ])
      ], 2)
    ]),
    _: 1
  }, 512))
}