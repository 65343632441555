import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_page = _resolveComponent("q-page")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createBlock(_component_q_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_Loading, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_q_page, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["loading"])
    ]),
    _: 3
  }))
}