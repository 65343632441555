
import { ref, defineComponent, onMounted, onUnmounted } from "vue";
import _ from "lodash"; // TODO: I probably don't need the whole lodash library

import ValuesSvc from "@/assets/js/valuesSvc";
import useEmitter from "@/assets/js/useEmitter";

import SliderPresetModel from "./SliderPresetModel";
import { SliderValuesModel } from "../slider/SliderModel";

export default defineComponent({
	name: "SliderPresets",
	emits: ["presetSelected"],
	setup() {
		const emitter = useEmitter();

		const presetSelected = ref<SliderPresetModel | null>(null);
		const sliderPresetValues = ref<SliderPresetModel[]>([]);
		const sliderValues = ref<SliderValuesModel>({
			efficiency: null,
			performance: null,
			quality: null,
			size: {
				max: null,
				min: null
			},
			price: null
		});

		function setPreset(preset: SliderPresetModel) {
			// Preset gets set on the slidersUpdated event
			emitter.emit("presetSelected", preset);
		}

		function onSlidersUpdated() {
			presetSelected.value = null;

			if (!sliderPresetValues.value.length) return;

			sliderPresetValues.value.some((preset: SliderPresetModel) => {
				if (_.isEqual(sliderValues.value, preset.sliderValues)) {
					presetSelected.value = preset;
					return true; // acts as a "break"
				}
				return false;
			});
		}

		onMounted(() => {
			sliderPresetValues.value = ValuesSvc.values.presets;
			emitter.on("slidersUpdated", onSlidersUpdated);
		});

		onUnmounted(() => {
			emitter.off("slidersUpdated", onSlidersUpdated);
		});

		return {
			presetSelected,
			sliderPresetValues,
			setPreset
		};
	}
});
