
import { defineComponent, PropType } from "vue";
import { CarScoreModel } from "../../CarModel";

export default defineComponent({
	name: "RatingTypeBase",
	props: {
		title: {
			type: String,
			required: true
		},
		score: {
			type: Object as PropType<CarScoreModel>,
			required: true
		}
	},
	setup() {
		const widthCover = (cover: number) => {
			// TODO: should I do this in the car class in the API?
			return 100 - cover + "%";
		};

		return {
			widthCover
		};
	}
});
