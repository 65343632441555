
import { computed, defineComponent, PropType } from "vue";

import Utilities from "@/assets/js/utilities";
import useRouteFunctions from "@Core/router/useRouteFunctions";
import UserSvc from "CarsJSAssets/userSvc";

import CarModel from "./CarModel";

import OpenGallery from "../gallery/openGallery/OpenGallery.vue";
import Ratings from "./ratings/ratings.vue";
import SearchLocal from "./searchLocal/SearchLocal.vue";
import SecondaryLinks from "./secondaryLinks/SecondaryLinks.vue";

interface EfficientEngineType {
	icon: string;
	color: string;
}

interface EfficientEngineTypes {
	[key: string]: EfficientEngineType;
}

export default defineComponent({
	name: "Car",
	components: {
		OpenGallery,
		Ratings,
		SearchLocal,
		SecondaryLinks
	},
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		},
		layout: {
			type: String,
			default: "horizontal"
		},
		componentName: {
			type: String,
			default: "Card"
		}
	},
	setup(props) {
		const routeFunctions = useRouteFunctions();

		const engineTags: EfficientEngineTypes = {
			Electric: {
				icon: "bolt",
				color: "green-5"
			},
			"Plug-In Hybrid": {
				icon: "electrical_services",
				color: "green-5"
			},
			Hybrid: {
				icon: "energy_savings_leaf",
				color: "blue-4"
			}
		};

		const engineTag = computed(() => {
			return engineTags[props.car.engine.type];
		});

		const carLinkParams = computed(() => {
			return {
				make: routeFunctions.encodeSlugForUrl(props.car.make),
				model: routeFunctions.encodeSlugForUrl(props.car.model),
				trim: routeFunctions.encodeSlugForUrl(props.car.trim)
			};
		});
		const toolTipArticle = computed(() => {
			return Utilities.article(props.car.name);
		});

		const isSaved = computed(() => {
			// TODO: car.saved property will probably go away once CarsSvc is dismantled; also could be a getter / setter if Car turns into a class ever
			return props.car.saved || UserSvc.user.savedCars.includes(props.car.id);
		});

		return {
			engineTag,
			carLinkParams,
			toolTipArticle,
			Utilities,
			isSaved
		};
	}
});
