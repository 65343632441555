import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "gallery",
  ref: "gallery"
}
const _hoisted_2 = {
  key: 0,
  class: "descriptionContainer galleryDescriptionContainer"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper_container = _resolveComponent("swiper-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.car.images.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", {
            class: "description galleryDescription",
            innerHTML: _ctx.car.images.description
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_swiper_container, {
      style: {
				'--swiper-theme-color': '#e35f28'
			},
      "slides-per-view": 1,
      "initial-slide": _ctx.openSlide,
      "auto-height": true,
      lazy: true,
      loop: true,
      navigation: true,
      pagination: { clickable: true }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.car.images.gallery_paths, (path) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: path }, {
            default: _withCtx(() => [
              _createElementVNode("img", { src: path }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["initial-slide"])
  ], 512))
}