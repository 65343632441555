import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "carListSort" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_select, {
      modelValue: _ctx.carListSort,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.carListSort) = $event)),
      options: _ctx.sortOptions,
      "emit-value": "",
      "map-options": "",
      outlined: "",
      dense: _ctx.dense,
      "bg-color": "white",
      label: "Sort by"
    }, null, 8, ["modelValue", "options", "dense"])
  ]))
}