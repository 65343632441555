
import { defineComponent, PropType } from "vue";

import TextSvc from "@/assets/js/textSvc";

export default defineComponent({
	name: "SpecsSection",
	props: {
		specsObject: {
			type: Object as PropType<any>,
			required: true
		},
		// TODO: title could come from object name?
		specsTitle: {
			type: String,
			required: true
		}
	},
	setup() {
		const regularSpecDisplay = (name: string) => {
			return !cargoCapacitySpec(name) && !colorsSpec(name);
		};
		const colorsSpec = (name: string) => {
			return name === "colors"; // TODO: This is brittle
		};
		const cargoCapacitySpec = (name: string) => {
			return name === "cargo_capacity"; // TODO: This is brittle
		};
		const formattedName = (value: string) => {
			return value.replace(/_/g, " ");
		};

		return {
			regularSpecDisplay,
			colorsSpec,
			cargoCapacitySpec,
			formattedName,
			TextSvc
		};
	}
});
