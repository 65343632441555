import CarModel from "@/modules/carDisplay/CarModel";

export default function useCarListSort() {
	// TODO: Put sort in one place, either via the API, or here
	// Right now, sort gets sent to the server as a url param in the request, so initially the matched cars list is returned already sorted, and this method isn't used until you select a new sort option
	// Unless I might need the list to come pre-sorted, sort could just live here
	function buildEfficiencyArray(car: CarModel): number[] {
		const fuelArray: number[] = [];

		if (car.fuel.mpg_city) fuelArray.push(car.fuel.mpg_city);
		if (car.fuel.mpg_combined) fuelArray.push(car.fuel.mpg_combined);
		if (car.fuel.mpg_highway) fuelArray.push(car.fuel.mpg_highway);
		if (car.fuel.mpge) fuelArray.push(car.fuel.mpge);

		return fuelArray;
	}

	function efficiencyHigh(a: CarModel, b: CarModel) {
		const aArr = buildEfficiencyArray(a);
		const bArr = buildEfficiencyArray(b);

		const maxA = Math.max(...aArr);
		const maxB = Math.max(...bArr);

		return maxB - maxA;
	}
	// function efficiencyLow(a, b) {
	//     const aArr = buildEfficiencyArray(a);
	//     const bArr = buildEfficiencyArray(b);

	//     const maxA = Math.min(...aArr);
	//     const maxB = Math.min(...bArr);

	//     return maxA - maxB;
	// }
	function performanceHigh(a: CarModel, b: CarModel) {
		return b.scores.performance.score - a.scores.performance.score;
	}
	function qualityHigh(a: CarModel, b: CarModel) {
		return b.scores.quality.score - a.scores.quality.score;
	}
	function priceHigh(a: CarModel, b: CarModel) {
		return b.price - a.price;
	}
	function priceLow(a: CarModel, b: CarModel) {
		return a.price - b.price;
	}
	function sizeHigh(a: CarModel, b: CarModel) {
		return b.scores.size.score - a.scores.size.score;
	}
	function sizeLow(a: CarModel, b: CarModel) {
		return a.scores.size.score - b.scores.size.score;
	}
	function defaultSort(a: CarModel, b: CarModel) {
		return b.scores.total.score - a.scores.total.score;
	}

	const setSort = (sort: string, carList: CarModel[]) => {
		// Set Orderby
		switch (sort) {
			case "efficiencyhigh":
				carList.sort(efficiencyHigh);
				break;
			// case "efficiencylow":
			// carList.sort(efficiencyLow);
			// 	break;
			case "performancehigh":
				carList.sort(performanceHigh);
				break;
			// case "performancelow":
			// carList.sort(performanceLow);
			// 	break;
			case "sizebig":
				carList.sort(sizeHigh);
				break;
			case "sizesmall":
				carList.sort(sizeLow);
				break;
			case "qualityhigh":
				carList.sort(qualityHigh);
				break;
			// case "qualitylow":
			// carList.sort(qualityLow);
			// 	break;
			case "pricehigh":
				carList.sort(priceHigh);
				break;
			case "pricelow":
				carList.sort(priceLow);
				break;
			default:
				carList.sort(defaultSort);
				break;
		}
	};

	return {
		setSort
	};
}
