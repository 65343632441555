import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "carList" }
const _hoisted_2 = { class: "matchedCarsTop" }
const _hoisted_3 = {
  key: 0,
  class: "titleBox matchedCarsTitleBox"
}
const _hoisted_4 = {
  key: 0,
  class: "titleContainer matchedCarsTitleContainer"
}
const _hoisted_5 = { class: "title matchedCarsTitle" }
const _hoisted_6 = {
  key: 1,
  class: "descriptionContainer matchedCarsDescriptionContainer"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "contentContainer matchedCarsContentContainer" }
const _hoisted_9 = {
  id: "matchedCarsResultsContainer",
  class: "matchedCarsResultsContainer"
}
const _hoisted_10 = {
  key: 0,
  class: "matchedCarsContent"
}
const _hoisted_11 = {
  key: 0,
  class: "col-12"
}
const _hoisted_12 = { class: "col-12 col-lg-6" }
const _hoisted_13 = {
  key: 0,
  class: "actionsContainer matchedCarsActionsContainer"
}
const _hoisted_14 = {
  key: 1,
  class: "noMatchesContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarListSort = _resolveComponent("CarListSort")!
  const _component_InFeedAd = _resolveComponent("InFeedAd")!
  const _component_CarDisplay = _resolveComponent("CarDisplay")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_NoMatches = _resolveComponent("NoMatches")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title || _ctx.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.title), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", {
                    class: "description matchedCarsDescription",
                    innerHTML: _ctx.description
                  }, null, 8, _hoisted_7)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.fullCarList?.length > 0)
        ? (_openBlock(), _createBlock(_component_CarListSort, {
            key: 1,
            modelValue: _ctx.carListSortModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.carListSortModel) = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_ctx.fullCarList?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", {
                class: _normalizeClass(["row", [_ctx.$viewport.largeScreens ? 'q-col-gutter-xl' : 'q-col-gutter-lg']])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carsShown, (car, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: car.id
                  }, [
                    (index % 4 === 0 && index > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_InFeedAd, { dataAdSlot: _ctx.inFeedAdId }, null, 8, ["dataAdSlot"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_CarDisplay, { car: car }, null, 8, ["car"])
                    ])
                  ], 64))
                }), 128))
              ], 2),
              (_ctx.carsLeft > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("a", {
                      href: "",
                      class: "loadMoreCarsAction",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.loadMoreCars()), ["prevent"]))
                    }, _toDisplayString(_ctx.loadNextText), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_inner_loading, {
                showing: _ctx.carListLoading,
                label: "Updating matches!",
                "label-class": "text-primary",
                "label-style": "font-size: 2em"
              }, null, 8, ["showing"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _renderSlot(_ctx.$slots, "emptyList", {}, () => [
                _createVNode(_component_NoMatches)
              ])
            ]))
      ])
    ])
  ]))
}