import CarModel from "@/modules/carDisplay/CarModel";
import UserSvc from "CarsJSAssets/userSvc";

// TODO: Turn off Car Connection

interface AffiliateInfoModel {
	car: CarModel;
	name: string;
	path: string;
	type?: string;
}

const affiliateDefaults = {
	edmundsLinkBase: "http://www.jdoqocy.com/click-7360171-10364102?url=",
	theCarConnectionLinkBase: "https://www.tkqlhce.com/click-7360171-14006148?url="
};
const currentYear = new Date().getFullYear();

export default {
	formatAffiliateLink: function (affiliateInfo: AffiliateInfoModel) {
		if (affiliateInfo.name == "Edmunds.com") {
			return this.formatEdmundsLink(affiliateInfo);
		} else if (affiliateInfo.name == "TheCarConnection.com") {
			return this.formatTheCarConnectionLink(affiliateInfo);
		}
	},
	formatTheCarConnectionLink: function (affiliateInfo: AffiliateInfoModel) {
		// Note: As of 9/2021 TCC dealer quote page breaks if you append the link with a zip code

		function formatText(text: string) {
			return text.replace(" ", "-").toLowerCase();
		}

		const car = affiliateInfo.car;
		const make = formatText(car.make);
		const model = formatText(car.model);
		const modelSeries = car.model_series ? formatText(car.model_series) : "";

		const modelOrModelSeries = modelSeries ? modelSeries : model;

		const theCarConnectionCorpLink = "https://www.thecarconnection.com/s/0/ab/" + make + "_" + modelOrModelSeries;

		return affiliateDefaults.theCarConnectionLinkBase + theCarConnectionCorpLink;
	},
	formatEdmundsLink: function (affiliateInfo: AffiliateInfoModel) {
		const car = affiliateInfo.car;
		let edmundsMake = car.make.toLowerCase();
		let edmundsModel = car.model;
		let submodelAppend = "";
		// let trimAppend = "";

		const edmundsModelYear = car.year < currentYear ? car.year : Math.max(car.year, currentYear);

		const zipCodeAppend = UserSvc.user.zipCode ? "&zip=" + UserSvc.user.zipCode : "";

		if (affiliateInfo.type == "quote") edmundsMake = edmundsMake.replace(" ", "-");

		if (!submodelAppend) {
			const submodelsArray = ["Hybrid", "Coupe", "Hatchback", "Sedan", "Convertible"];

			const convertibleAliasArray = ["Cabriolet", "Spider"];
			const modelText = convertibleAliasArray.includes(car.trim) ? "Convertible" : car.trim;

			submodelAppend = submodelsArray.includes(modelText) ? "&submodelid=" + modelText : "";
		}

		// Manufacturer Overrides
		let lastLetter = "";

		if (car.make == "BMW") {
			const $firstLetter = car.model.substring(0, 1);
			lastLetter = car.model.slice(-1);
			const approvedFirstLetters = ["X", "M", "Z"];

			if ((!approvedFirstLetters.includes($firstLetter) || (approvedFirstLetters.includes($firstLetter) && car.model.length > 2)) && lastLetter !== "M") {
				edmundsModel = car.model_series as string; // $firstLetter + "-Series";
				submodelAppend = "&submodelid=" + car.model;
			}
		} else if (car.make == "Ford") {
			// const fordTrims = ["SHO"];
			// if (fordTrims.includes(car.trim)) {
			// 	trimAppend = "&trim=" + car.trim;
			// }
		} else if (car.make == "Honda") {
			const civicTrims = ["Si"];

			if (civicTrims.includes(car.trim)) {
				submodelAppend = "&submodelid=" + car.trim;
			}
		} else if (car.make == "Mazda") {
			const mazdaModels = ["Mazda2", "Mazda3", "Mazda5", "Mazda6"];

			if (mazdaModels.includes(car.model)) {
				lastLetter = car.model.slice(-1);

				edmundsModel = lastLetter;
			}
		} else if (car.make == "Mercedes-Benz") {
			if (car.model.includes("AMG")) {
				let mercModel = car.model.replace("AMG", "");
				mercModel = mercModel.replace(/\s/g, "");

				edmundsModel = mercModel + " AMG";
			} else {
				const mercModelArray = car.model.split(" ");
				edmundsModel = mercModelArray[0] + "-class";
				// const trimArray = car.model + "," + car.model.replace(/\s/g, "");
				// trimAppend = "&trim=" + trimArray;
			}
		} else if (car.make == "MINI") {
			// const miniTrims = ["S"];
			// if (miniTrims.includes(car.trim)) {
			// 	trimAppend = "&trim=" + car.trim;
			// }
		} else if (car.make == "Porsche") {
			// const porscheTrims = ["S", "GTS"];
			// if (porscheTrims.includes(car.trim)) {
			// 	trimAppend = "&trim=" + car.trim;
			// }
		} else if (car.make == "Subaru") {
			// const subaruTrims = ["STI"];
			// if (subaruTrims.includes(car.trim)) {
			// 	trimAppend = "&trim=" + car.trim;
			// }
		} else if (car.make == "Volkswagen") {
			const golfModels = ["GTI"];

			if (affiliateInfo.type == "quote" && golfModels.includes(car.model)) {
				edmundsModel = "Golf " + car.model;
			}
		}

		edmundsModel = edmundsModel.replaceAll(" ", "-").toLowerCase();
		let edmundsUrl = "";

		if (affiliateInfo.type == "quote") {
			// Edmunds.com Quote Link
			edmundsUrl = "http://www.edmunds.com/" + edmundsMake + "/" + edmundsModel + "/" + edmundsModelYear + "/quickquotes.html?epid=100" + zipCodeAppend;
		} else {
			// Edmunds.com Search Local Link
			edmundsUrl = "https://www.edmunds.com/inventory/srp.html?model=" + edmundsModel; // + "&make=" + edmundsMake + priceAppend + drivetrainAppend + submodelAppend + trimAppend + zipCodeAppend; //  // As of 9/2021, everything after the first append gets stripped between me and where you land, and passing the model seems to be the most useful
		}

		return affiliateDefaults.edmundsLinkBase + edmundsUrl;
	}
};
