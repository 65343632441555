import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "quizStep" }
const _hoisted_2 = { class: "titleContainer quizStepTitleContainer" }
const _hoisted_3 = { class: "title quizStepTitle" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "q-ml-sm"
}
const _hoisted_6 = { class: "contentContainer quizStepContentContainer" }
const _hoisted_7 = {
  key: 0,
  class: "descriptionContainer quizStepDescriptionContainer"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", {
          innerHTML: _ctx.quizStep.title
        }, null, 8, _hoisted_4),
        (_ctx.quizStep.input?.type === 'checkbox')
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(Select 2)"))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.quizStep.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", {
              class: "description quizStepDescription",
              innerHTML: _ctx.quizStep.description
            }, null, 8, _hoisted_8)
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.quizStep.component || 'QuizStepInputs'), { quizStep: _ctx.quizStep }, null, 8, ["quizStep"]))
    ])
  ]))
}