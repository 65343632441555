import axios from "CarsJSAssets/useAxios";

import ValuesModel, { ValuesPayload } from "./ValuesModel";
import SliderModel from "@WhichCar/src/modules/sliders/slider/SliderModel";
import HelpTextGroup from "CarsJSAssets/HelpTextModel";

// TODO STORE: use a store for values
const values: ValuesModel = {
	helpTexts: [],
	quizSteps: [],
	presets: [],
	sliders: []
};

export default {
	updateValues: async (values: ValuesModel) => {
		const response = await axios.post("/api/values/", { values });
		return response.data;
	},
	getValues: async (params?: ValuesPayload) => {
		const response = await axios.get("/api/values/", { params });

		for (const key in response.data) {
			values[key as keyof ValuesModel] = response.data[key];
		}

		return values;
	},
	getSliderValues: async (params?: ValuesPayload) => {
		const response = await axios.get("/api/values/sliders/", { params });

		values.sliders = response.data;

		return values.sliders;
	},
	updateSliders: async (sliders: SliderModel[]) => {
		const response = await axios.put("/api/values/sliders/", { sliders });

		values.sliders = response.data;

		return values.sliders;
	},
	findSlider: function (name: string): SliderModel | null {
		if (!values.sliders?.length) return null;

		const slider = values.sliders.find((slider: SliderModel): boolean => slider.name === name);

		if (!slider) return null;

		return slider;
	},
	findHelpTextGroup: function (name: string): HelpTextGroup | null {
		if (!values.helpTexts?.length) return null;

		const helpTextGroup = values.helpTexts.find((helpTextGroup: HelpTextGroup): boolean => helpTextGroup.name === name);

		if (!helpTextGroup) return null;

		return helpTextGroup;
	},
	values
};
