
import { defineComponent, getCurrentInstance, PropType } from "vue";

import ValuesSvc from "@/assets/js/valuesSvc";

import CarModel from "../CarModel";
import SliderModel from "@/modules/sliders/slider/SliderModel";

import defaultRating from "./ratingTypes/defaultRating.vue";
import efficiencyRating from "./ratingTypes/efficiencyRating.vue";
import performanceRating from "./ratingTypes/performanceRating.vue";
import qualityRating from "./ratingTypes/qualityRating.vue";
import sizeRating from "./ratingTypes/sizeRating.vue";

export default defineComponent({
	name: "Ratings",
	components: {
		efficiencyRating,
		performanceRating,
		qualityRating,
		sizeRating,
		defaultRating
	},
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		},
		layout: {
			type: String,
			default: "horizontal"
		}
	},
	setup() {
		const values = ValuesSvc.values;
		const currentComponent = getCurrentInstance();
		const componentList = currentComponent?.type as any; // typing as `any` because `components` property exists but the TS interface doesn't contain it

		const componentName = (slider: SliderModel) => {
			if (slider.name === "price") return;

			if (componentList.components[`${slider.name}Rating`]) return `${slider.name}Rating`;

			return `defaultRating`;
		};

		return {
			values,
			componentName
		};
	}
});
