
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import useRouteFunctions from "@Core/router/useRouteFunctions";

import SelectMakeModelModel from "../selectMakeModel/SelectMakeModelModel";

import SelectMakeModel from "../selectMakeModel/SelectMakeModel.vue";
import QuickLinks from "../quickLinks/QuickLinks.vue";

export default defineComponent({
	name: "FindCars",
	components: {
		SelectMakeModel,
		QuickLinks
	},
	props: {
		title: {
			type: String,
			required: true
		},
		description: {
			type: String,
			default: ""
		},
		destination: {
			type: String,
			required: true
		},
		actionContent: {
			type: String,
			required: true
		},
		showQuickLinks: {
			type: Boolean,
			default: true
		}
	},
	setup(props) {
		const router = useRouter();
		const routeFunctions = useRouteFunctions();
		const selectMakeModel = ref<SelectMakeModelModel>({
			make: "",
			model: "",
			trim: ""
		});

		const makeModelSelected = (props: SelectMakeModelModel) => {
			selectMakeModel.value = props;
		};

		const go = () => {
			router.push({ name: props.destination, params: routeFunctions.buildURLParams(selectMakeModel.value, props.destination) });
		};

		return {
			selectMakeModel,
			makeModelSelected,
			go
		};
	}
});
