
import { computed, defineComponent } from "vue";

import useViewport from "@/assets/js/useViewport";

// Loading is embedded in page layouts because in order to look right, it needs to be between <q-page-container /> and <q-drawer /> & <q-page />

export default defineComponent({
	name: "CenteredLayout",
	props: {
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const viewport = useViewport();

		const contentPaddingClasses = computed(() => {
			if (viewport.largeScreens) return "q-px-xl q-py-lg";
			if (viewport.extraSmall) return "q-pa-md";

			return "q-pa-lg";
		});

		return { contentPaddingClasses };
	}
});
