
import { ref, computed, onMounted, PropType, defineComponent, onUnmounted } from "vue";

import Utilities from "@/assets/js/utilities";
import TextSvc from "@/assets/js/textSvc";
import useHelpText from "CarsJSAssets/useHelpText";
import useEmitter from "@/assets/js/useEmitter";
import useSliders from "../useSliders";

import SliderModel, { SliderValuesModel, SliderValueType } from "@/modules/sliders/slider/SliderModel";

import SliderPresetModel from "../presets/SliderPresetModel";

export default defineComponent({
	name: "Slider",
	emits: ["sliderUpdated"],
	props: {
		slider: {
			type: Object as PropType<SliderModel>,
			required: true
		}
	},
	setup(props) {
		const emitter = useEmitter();
		const sliders = useSliders();
		const { setSliderHelpText } = useHelpText();
		const showHelpBubble = ref(false); // TODO: change this to an actual tooltip / overlay; note: wcirfm already has something called 'tooltip'

		const sliderValue = ref<SliderValueType>(props.slider.options.value);

		const sliderName = computed((): string => {
			return props.slider.name;
		});

		const sliderText = computed(() => {
			// TODO: it's hard to follow how this text is getting generated
			const textArray = []; // TODO: I don't think this needs to be an array anymore now that quasar range-slider uses an object

			// TODO: make this applicable to more than just price
			if (sliderName.value === "price") {
				textArray.push({
					header: priceHeaderText(sliderValue.value as number)
				});

				return textArray;
			}

			if (typeof sliderValue.value === "number") {
				textArray.push(setSliderHelpText(sliderValue.value, sliderName.value));
			} else {
				textArray.push(setSliderHelpText(sliderValue.value!.min as number, sliderName.value));
				textArray.push(setSliderHelpText(sliderValue.value!.max as number, sliderName.value));
			}

			return textArray;
		});

		const showIncDec = computed(() => {
			return typeof sliderValue.value === "number";
		});

		function priceHeaderText(price: number) {
			if (price === 0) return "Any Price";

			if (price === props.slider.options.max) return Utilities.currency(price) + "+";

			return Utilities.currency(price);
		}
		function increment() {
			if (typeof sliderValue.value !== "number") return;

			if (props.slider.options.interval) sliderValue.value += props.slider.options.interval;
			else sliderValue.value++;

			updateSlider();
		}
		function decrement() {
			if (typeof sliderValue.value !== "number") return;

			if (props.slider.options.interval) sliderValue.value -= props.slider.options.interval;
			else sliderValue.value--;

			updateSlider();
		}
		function sliderChange(_val: number) {
			updateSlider();
		}
		function updateSlider() {
			sliders.slidersConfig.sliderValues[sliderName.value] = sliderValue.value;
			emitter.emit("sliderUpdated", sliderValue.value);
		}
		function onPresetSelected(preset: SliderPresetModel) {
			sliderValue.value = preset.sliderValues[sliderName.value as keyof SliderValuesModel];

			updateSlider();
		}

		const markerValues = [];
		let markerValue = 0;
		do {
			markerValues.push(markerValue);
			markerValue = markerValue + props.slider.options.markerInterval;
		} while (props.slider.options.max >= markerValue);

		function setMarkerLabel(value: number): string {
			if (props.slider.meaning === "value") {
				let text = value.toString();
				if (props.slider.name === "price") text = Utilities.currency(value);

				return `${text}${value === props.slider.options.max ? "+" : ""}`;
			}

			return setSliderHelpText(value, props.slider.name).header_short as string;
		}

		const markerLabels = markerValues.map((value) => {
			return {
				label: setMarkerLabel(value),
				value
			};
		});

		onMounted(() => {
			const savedValue = sliders.slidersConfig.sliderValues[sliderName.value];

			if (
				(savedValue !== null && typeof savedValue === "number") ||
				(savedValue?.min !== undefined && savedValue.min !== null && typeof savedValue.min === "number")
			)
				sliderValue.value = sliders.slidersConfig.sliderValues[sliderName.value];

			emitter.on("presetSelected", onPresetSelected);
		});

		onUnmounted(() => {
			emitter.off("presetSelected", onPresetSelected);
		});

		return {
			TextSvc,
			sliderValue,
			showHelpBubble,
			sliderText,
			showIncDec,
			increment,
			decrement,
			sliderChange,
			markerLabels,
			onPresetSelected
		};
	}
});
