import mitt, { Emitter } from "mitt";

import CarModel from "@/modules/carDisplay/CarModel";
import SliderPresetModel from "@/modules/sliders/presets/SliderPresetModel";
import { SliderValuesModel, SliderValueType } from "@/modules/sliders/slider/SliderModel";
import { AdditionalOptionsSelectedModel } from "@/modules/sliders/additionalOptions/AdditionalOptionsModel";
import { QuizStepInputSelectedModel } from "@/modules/quiz/quizStep/QuizStepModel";

type Events = {
	carSaved: CarModel;
	carUnsaved: CarModel;
	presetSelected: SliderPresetModel;
	quizStepChange: QuizStepInputSelectedModel;
	sliderOptionsChanged: AdditionalOptionsSelectedModel;
	sliderSortChanged: string;
	sliderUpdated: SliderValueType;
	slidersUpdated: SliderValuesModel;
	toggleDrawer: void;
	showSliders: void;
};

// TODO: use "onX" naming convention when doing something on an event

const emitter: Emitter<Events> = mitt<Events>();

export default function useEmitter() {
	return emitter;
}
