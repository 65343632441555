import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ratings", [_ctx.layout]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values.sliders, (slider, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName(slider)), {
        slider: slider,
        car: _ctx.car,
        key: index
      }, null, 8, ["slider", "car"]))
    }), 128))
  ], 2))
}