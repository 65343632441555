
import { defineComponent, PropType, ref } from "vue";

import CarModel from "@/modules/carDisplay/CarModel";

import SuggestedCarDisplay from "./SuggestedCarDisplay.vue";
import CarDisplay from "../../carDisplay/CarDisplay.vue";

export default defineComponent({
	name: "SuggestedCarContainer",
	components: {
		SuggestedCarDisplay,
		CarDisplay
	},
	props: {
		suggestedCar: {
			type: Object as PropType<CarModel>
		},
		title: {
			type: String
		}
	},
	setup(props) {
		const carDisplayDialog = ref(false);

		const openCarInfo = () => {
			if (props.suggestedCar?.id) carDisplayDialog.value = true;
		};

		return {
			openCarInfo,
			carDisplayDialog
		};
	}
});
