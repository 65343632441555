import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "quizStepInputs" }
const _hoisted_2 = {
  key: 0,
  id: "quizStepFormElements",
  class: "formElements quizStepFormElements"
}
const _hoisted_3 = { class: "row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizStepInput = _resolveComponent("QuizStepInput")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.quizStep.options.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quizStep.options, (quizStepOption, index) => {
            return (_openBlock(), _createBlock(_component_QuizStepInput, {
              modelValue: _ctx.quizInputValue,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.quizInputValue) = $event)),
                _ctx.inputChange
              ],
              key: quizStepOption,
              class: _normalizeClass([
					_ctx.quizStep.input.type + 'InputContainer',
					_ctx.quizStep.input.name + 'InputContainer',
					{
						active: _ctx.quizInputValue === quizStepOption || (_ctx.quizStep.input.type === 'checkbox' && _ctx.quizInputValue.includes(quizStepOption)),
						activeHighlight: index >= _ctx.activeHoverClass[0] && index <= _ctx.activeHoverClass[1]
					}
				]),
              quizStepOption: quizStepOption,
              quizStep: _ctx.quizStep,
              slider: _ctx.slider,
              helpTextGroup: _ctx.helpTextGroup,
              onMouseover: ($event: any) => (_ctx.inputMouseover(quizStepOption)),
              onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputMouseover(null)))
            }, null, 8, ["modelValue", "class", "quizStepOption", "quizStep", "slider", "helpTextGroup", "onMouseover", "onUpdate:modelValue"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.quizStep.input.type === 'checkbox' && _ctx.quizInputValue.length === 1)
        ? (_openBlock(), _createBlock(_component_Message, {
            key: 0,
            type: "warning",
            text: "Select one more!"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}