
import { computed, defineComponent, PropType, ref } from "vue";

import OpenGalleryModes from "./openGalleryModesEnum";

import Gallery from "../Gallery.vue";
import CarModel from "@/modules/carDisplay/CarModel";

export default defineComponent({
	name: "OpenGallery",
	components: {
		Gallery
	},
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		},
		src: {
			type: String,
			default: ""
		},
		mode: {
			type: String,
			default: OpenGalleryModes.Default
		},
		openSlide: {
			type: Number
		}
	},
	setup(props) {
		const dialog = ref();

		const imagePath = computed(() => {
			return props.src ? props.src : props.car.images.thumbnail_path;
		});

		const slideToOpen = computed(() => {
			if (props.openSlide !== undefined) return props.openSlide;

			const thumbnailPathArray = props.car.images.thumbnail_path.split("/");
			const lastItem = thumbnailPathArray[thumbnailPathArray.length - 1];
			const lastItemArray = lastItem.split("_");
			const index = lastItemArray[lastItemArray.length - 1].split(".");
			return parseInt(index[0]) - 1;
		});

		const openGallery = () => {
			dialog.value = true;
		};

		return { dialog, openGallery, imagePath, slideToOpen, OpenGalleryModes };
	}
});
