
import { computed, defineComponent, PropType } from "vue";

import SliderModel from "@/modules/sliders/slider/SliderModel";
import CarModel from "../../CarModel";

import RatingTypeBase from "./RatingTypeBase.vue";

export default defineComponent({
	name: "sizeRating", // rating component names don't start with an uppercase letter so they can match slider.name in Ratings.vue dynamic component
	components: {
		RatingTypeBase
	},
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		},
		slider: {
			type: Object as PropType<SliderModel>,
			required: true
		}
	},
	setup(props) {
		function evaluateCargoSpace(cargo: number | undefined): number {
			if (!cargo) return 0;

			return cargo;
		}

		const maxCargo = computed(() => {
			if (props.car.interior.cargo_capacity) {
				return Math.max(
					evaluateCargoSpace(props.car.interior.cargo_capacity.trunk),
					evaluateCargoSpace(props.car.interior.cargo_capacity.first_row_down),
					evaluateCargoSpace(props.car.interior.cargo_capacity.second_row_down)
				);
			}

			return 0;
		});

		return {
			maxCargo
		};
	}
});
