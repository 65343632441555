const savedCars: string[] = [];

let user = {
	savedCars,
	zipCode: ""
};

export default {
	getSavedCars: async () => {
		// ex: [856792644, 947147555, 466752647]

		return new Promise(function (resolve) {
			const savedCars = localStorage.getItem("savedCars") || "";
			if (savedCars) {
				let savedCarsArray: string[] = JSON.parse(savedCars);
				savedCarsArray.forEach((car) => {
					user.savedCars.push(car);
				});
			}

			let zipCode = localStorage.getItem("zipCode") || "";
			if (zipCode) {
				zipCode = JSON.parse(zipCode);
				user.zipCode = zipCode;
			}

			resolve(user);
		});
	},
	saveCar: function (carId: string) {
		return new Promise(function (resolve) {
			user.savedCars.push(carId);

			localStorage.setItem("savedCars", JSON.stringify(user.savedCars));

			resolve(true);
		});
	},
	unsaveCar: function (carId: string) {
		return new Promise(function (resolve) {
			const index = user.savedCars.indexOf(carId);
			if (index > -1) user.savedCars.splice(index, 1);

			localStorage.setItem("savedCars", JSON.stringify(user.savedCars));

			resolve(true);
		});
	},
	// getZipCode: function() {
	//     return new Promise(function(resolve) {
	//         let zipCode = localStorage.getItem('zipCode');

	//         if (zipCode) {
	//             zipCode = JSON.parse(savedCars);
	//         } else {
	//             zipCode = '';
	//         }

	//         user.zipCode = zipCode;

	//         resolve(
	//             zipCode
	//         );
	//     });
	// },
	setZipCode: function (zipCode: string) {
		return new Promise(function (resolve) {
			user.zipCode = zipCode;

			localStorage.setItem("zipCode", JSON.stringify(user.zipCode));

			resolve(true);
		});
	},
	// TODO STORE: use a store for this, don't hang off of service
	user
};
