import ValuesSvc from "CarsJSAssets/valuesSvc";
import { HelpTextValueModel } from "./HelpTextModel";

export default function useHelpText() {
	const setSliderHelpTextVal = (value: number, base: number): number => {
		// This might not belong here
		let val = value > 0 ? (value / base) * 10 : 0;

		if (val > 0) {
			val = Math.floor(val + 1); // adjust for array
		}

		return val;
	};

	const setSliderHelpText = (value: number, name: string): HelpTextValueModel => {
		const helpTextGroup = ValuesSvc.findHelpTextGroup(name);
		const slider = ValuesSvc.findSlider(name);

		if (!slider || !helpTextGroup)
			return {
				header: "",
				header_short: "",
				rating: "",
				meaning: "",
				paragraph: "",
				value: 0
			};

		const helpTextVal = setSliderHelpTextVal(value, slider!.options.max);
		const helpText = helpTextGroup!.values[helpTextVal];

		return {
			header: helpText.header,
			header_short: helpText.header_short,
			rating: helpText.specific_rating,
			meaning: helpText.meaning,
			paragraph: helpText.paragraph,
			value
		};
	};

	return {
		setSliderHelpTextVal,
		setSliderHelpText
	};
}
