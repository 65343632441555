
import { defineComponent } from "vue";

// Loading is embedded in page layouts because in order to look right, it needs to be between <q-page-container /> and <q-drawer /> & <q-page />

export default defineComponent({
	name: "ContentLayout",
	props: {
		loading: {
			type: Boolean,
			default: false
		}
	}
});
