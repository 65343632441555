import { App, defineAsyncComponent } from "vue";

const AdsenseAd = defineAsyncComponent(() => import("GeneralComponents/adSpace/AdsenseAd.vue"));
const InFeedAd = defineAsyncComponent(() => import("GeneralComponents/adSpace/InFeedAd.vue"));

const Tooltip = defineAsyncComponent(() => import("GeneralComponents/misc/Tooltip.vue"));
const Loading = defineAsyncComponent(() => import("GeneralComponents/misc/Loading.vue"));
const Message = defineAsyncComponent(() => import("GeneralComponents/misc/Message.vue"));
const Card = defineAsyncComponent(() => import("GeneralComponents/misc/Card.vue"));

const Dialog = defineAsyncComponent(() => import("LayoutComponents/dialog/Dialog.vue"));

export default function registerGlobalComponents(app: App) {
	app.component("AdsenseAd", AdsenseAd);
	app.component("InFeedAd", InFeedAd);

	app.component("Tooltip", Tooltip);
	app.component("Loading", Loading);
	app.component("Message", Message);
	app.component("Card", Card);

	app.component("Dialog", Dialog);
}
