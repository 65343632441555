
import { defineComponent, PropType } from "vue";

import SliderModel from "@/modules/sliders/slider/SliderModel";
import CarModel from "../../CarModel";

import RatingTypeBase from "./RatingTypeBase.vue";

export default defineComponent({
	name: "efficiencyRating", // rating component names don't start with an uppercase letter so they can match slider.name in Ratings.vue dynamic component
	components: {
		RatingTypeBase
	},
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		},
		slider: {
			type: Object as PropType<SliderModel>,
			required: true
		}
	}
});
