import { ref } from "vue";

import { SlidersConfigModel } from "./slider/SliderModel";

const slidersConfig = ref<SlidersConfigModel>({
	sliderValues: {
		efficiency: null,
		performance: null,
		quality: null,
		size: {
			max: null,
			min: null
		},
		price: null
	},
	options: {},
	sort: ""
});

function saveSliders() {
	localStorage.setItem("slidersConfig", JSON.stringify(slidersConfig.value));
}

export default function useSliders() {
	const saveSlidersConfig = (updatedSlidersConfig: SlidersConfigModel) => {
		slidersConfig.value.sliderValues = { ...slidersConfig.value.sliderValues, ...updatedSlidersConfig.sliderValues };
		slidersConfig.value.options = updatedSlidersConfig.options;
		slidersConfig.value.sort = updatedSlidersConfig.sort;

		saveSliders();
	};

	const viewSlidersConfig = (): SlidersConfigModel => {
		return slidersConfig.value;
	};

	const getSavedSlidersConfig = async (): Promise<SlidersConfigModel> => {
		return new Promise((resolve) => {
			const slidersConfigLS = localStorage.getItem("slidersConfig") || "";

			if (slidersConfigLS) {
				let slidersConfigModelLS: SlidersConfigModel = JSON.parse(slidersConfigLS);

				slidersConfig.value = { ...slidersConfig.value, ...slidersConfigModelLS };
			}

			resolve(slidersConfig.value);
		});
	};

	return {
		saveSlidersConfig,
		getSavedSlidersConfig,
		viewSlidersConfig,
		slidersConfig: slidersConfig.value
	};
}
