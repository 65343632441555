
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

import CarModel from "../carDisplay/CarModel";

import CarsSvc from "@/assets/js/carsSvc";
import UserSvc from "@/assets/js/userSvc";
import useEmitter from "@/assets/js/useEmitter";

import SidebarLayout from "LayoutComponents/layouts/SidebarLayout.vue";
import SideNav from "../layout/nav/sideNav/SideNav.vue";

import CarList from "../carList/CarList.vue";

export default defineComponent({
	name: "SavedCars",
	components: {
		SidebarLayout,
		SideNav,
		CarList
	},
	setup() {
		const emitter = useEmitter();

		const user = ref();
		const savedCars = ref<CarModel[]>([]);

		const unsaveCar = function (car: CarModel) {
			// TODO: user.saveCars is a list of IDs, savedCars.value is the actual cars... it might be nice to consolidate something so the list automatically updates when a car is unsaved
			savedCars.value = savedCars.value.filter(function (savedCar) {
				return savedCar.id != car.id;
			});
		};

		onMounted(async () => {
			user.value = UserSvc.user;

			if (user.value.savedCars.length > 0) savedCars.value = await CarsSvc.getMultipleCarsById(user.value.savedCars);

			emitter.on("carUnsaved", unsaveCar);
		});

		onUnmounted(() => {
			emitter.off("carUnsaved", unsaveCar);
		});

		return {
			savedCars,
			unsaveCar
		};
	}
});
