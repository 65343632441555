import { App } from "vue";
import Screen from 'quasar/src/plugins/Screen.js';;

import Viewport from "@Core/general/viewport";

let viewport: Viewport;

export default function useViewport(): Viewport {
	return viewport;
}

export function setupViewport(app: App) {
	viewport = new Viewport(Screen);
	app.config.globalProperties.$viewport = viewport;
}
