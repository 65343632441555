import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "carOption" }
const _hoisted_2 = { class: "suggestedCar" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 0,
  class: "imageContainer suggestedCarImageContainer"
}
const _hoisted_5 = ["src", "title"]
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "carDisplayTitle" }
const _hoisted_8 = { class: "msrp" }
const _hoisted_9 = {
  key: 1,
  class: "noneOption"
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.suggestedCar.id)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "suggestedCarContent",
            title: `View More Info on the ${_ctx.suggestedCar.name}`
          }, [
            (_ctx.suggestedCar.images.thumbnail_path)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: _ctx.suggestedCar.images.thumbnail_path,
                    width: "150",
                    height: "100",
                    title: _ctx.suggestedCar.images.description
                  }, null, 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.suggestedCar.name), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.Utilities.currency(_ctx.suggestedCar.price)), 1)
            ])
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.suggestedCar.message)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", {
              class: "noneOptionMessage textInactive",
              innerHTML: _ctx.suggestedCar.message
            }, null, 8, _hoisted_10)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}