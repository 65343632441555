
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "App",
	setup() {
		const router = useRouter();

		const page = computed(() => {
			const page = router.currentRoute;

			return {
				name: page.value.name,
				class: (page.value.name as string) + "Page"
			};
		});

		return {
			page
		};
	}
});
