import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pageLayout detail" }
const _hoisted_2 = {
  id: "inner",
  class: "siteWidth"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdsenseAd = _resolveComponent("AdsenseAd")!
  const _component_FindCars = _resolveComponent("FindCars")!
  const _component_CenteredLayout = _resolveComponent("CenteredLayout")!

  return (_openBlock(), _createBlock(_component_CenteredLayout, { class: "bg-white" }, {
    topAd: _withCtx(() => [
      _createVNode(_component_AdsenseAd, { dataAdSlot: "9423361275" })
    ]),
    bottomAd: _withCtx(() => [
      _createVNode(_component_AdsenseAd, { dataAdSlot: "1661594785" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FindCars, {
            title: "Start My Search",
            description: "Learn more about a particular car, view cars similar to it, and get suggestions if this car isn't quite right.",
            destination: "DetailCar",
            actionContent: "View Car"
          })
        ])
      ])
    ]),
    _: 1
  }))
}