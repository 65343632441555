import { RouteRecordRaw } from "vue-router";

import useRouteFunctions from "@Core/router/useRouteFunctions";

import SiteLayout from "LayoutComponents/layouts/SiteLayout.vue";
import PageRoot from "../modules/layout/layouts/PageRoot.vue";

import About from "../modules/about/About.vue";
import Contact from "../modules/contact/Contact.vue";
import DetailSelect from "../modules/detail/DetailSelect.vue";
import DetailCar from "../modules/detail/DetailCar.vue";
import Home from "../modules/home/Home.vue";
import MatchedCars from "../modules/matchedCars/MatchedCars.vue";
import Quiz from "../modules/quiz/Quiz.vue";
import SavedCars from "../modules/savedCars/SavedCars.vue";
import SimilarCarsSelect from "../modules/similarCars/SimilarCarsSelect.vue";
import SimilarCarsResults from "../modules/similarCars/SimilarCarsResults.vue";
import SuggestForMeSelect from "../modules/suggestForMe/SuggestForMeSelect.vue";
import SuggestForMeResults from "../modules/suggestForMe/SuggestForMeResults.vue";

import NotFound from "../modules/notFound/NotFound.vue";

const routeFunctions = useRouteFunctions();

// Notes:
// Keep titles & descriptions in line with those in /assets/php/headVariables.php

// TODO: look into doing this with SSR so I don't need to maintain the headVariables.php file in addition to this

// TODO: Similar / Suggest for Me results pages...
// 		should I used slashes between make/model/year to make better use of vue params? also could use dashes in car names... might not be as good for seo though?
// 		if so, set up a redirect from dashes to slashes

import "vue-router";
import { MetaTagModel } from "./RouteMetaModels";

declare module "vue-router" {
	interface RouteMeta {
		metaTags?: MetaTagModel[];
		description?: string;
		siteImgPath?: string;
		title?: string;
		twitterCardType?: string;
	}
}

const routes: RouteRecordRaw[] = [
	{
		path: "",
		name: "Root",
		component: SiteLayout,
		children: [
			{
				path: "/cars/",
				name: "DetailRoot",
				component: PageRoot,
				children: [
					{
						path: "",
						name: "DetailSelect",
						component: DetailSelect,
						meta: routeFunctions.setMetaData({
							title: "Start My Search",
							description: "Learn more about a car to find out if it's the right car for you."
						})
					},
					{
						// meta set in component
						path: ":make/:model/:trim",
						name: "DetailCar",
						component: DetailCar
					}
				]
			},
			{
				path: "About/",
				name: "About",
				component: About,
				meta: routeFunctions.setMetaData({
					title: "About",
					description:
						'WhichCarIsRightForMe.com was founded on the idea that car shopping and research should be easier, more intuitive, and not require you to "know cars" in order to find the right car.'
				})
			},
			{
				path: "Contact/",
				name: "Contact",
				component: Contact,
				meta: routeFunctions.setMetaData({
					title: "Contact",
					description: "Comments, praise, errors &amp; ommisions... let me hear it." // replicated on page
				})
			},
			{
				path: "",
				name: "Home",
				component: Home,
				meta: routeFunctions.setMetaData({
					title: "Find the Right Car",
					description:
						"Find the right car quickly and easily, based on what's most important to you. View cars based on efficiency, performance, size and quality that are within your price range."
				})
			},
			{
				path: "Matched-Cars/",
				name: "Matched-Cars",
				component: MatchedCars,
				meta: routeFunctions.setMetaData({
					title: "Best Matched Cars",
					description: "Use the sliders to find the perfect car."
				})
			},
			{
				path: "Quiz/",
				name: "Quiz",
				component: Quiz,
				meta: routeFunctions.setMetaData({
					title: "Quiz",
					description:
						"You don't need to know cars to find the right car. Answer a few basic questions and you'll find the best car for you in no time."
				})
			},
			{
				path: "Saved-Cars/",
				name: "Saved-Cars",
				component: SavedCars,
				meta: routeFunctions.setMetaData({
					title: "Saved Cars",
					description:
						"Conduct your car search more efficiently by saving your favorite cars and using the buttons on each car to view cars in your area."
				})
			},
			{
				path: "/Similar-Cars/",
				name: "SimilarCarsRoot",
				component: PageRoot,
				children: [
					{
						path: "",
						name: "SimilarCarsSelect",
						component: SimilarCarsSelect,
						meta: routeFunctions.setMetaData({
							title: "Find a Similar Car",
							description: "View similar cars based on the car you're considering."
						})
					},
					{
						// meta set in component
						path: ":carName",
						name: "SimilarCarsResults",
						component: SimilarCarsResults
					}
				]
			},
			{
				path: "/Suggest-For-Me/",
				name: "SuggestForMeRoot",
				component: PageRoot,
				children: [
					{
						path: "",
						name: "SuggestForMeSelect",
						component: SuggestForMeSelect,
						meta: routeFunctions.setMetaData({
							title: "Suggest Cars for Me",
							description: "Get suggestions for your next car based on a car you already know."
						})
					},
					{
						// meta set in component
						path: ":carName",
						name: "SuggestForMeResults",
						component: SuggestForMeResults
					}
				]
			},
			{
				path: "/:catchAll(.*)",
				component: NotFound,
				meta: routeFunctions.setMetaData({
					title: "CARp!",
					description: "That didn't work."
				})
			}
		]
	}
];

export default routes;
