
import { defineComponent } from "vue";

export default defineComponent({
	name: "DetailSection",
	props: {
		title: {
			type: String
		}
	}
});
