import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formContainer findCarsFormContainer findMatchedCarsFormContainer siteWidth-Inner" }
const _hoisted_2 = { class: "formElements findCarsFormElements findMatchedCarsFormElements" }
const _hoisted_3 = { class: "actionsContainer findCarsActionsContainer findMatchedCarsActionsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderPresets = _resolveComponent("SliderPresets")!
  const _component_Sliders = _resolveComponent("Sliders")!
  const _component_FindCars = _resolveComponent("FindCars")!

  return (_openBlock(), _createBlock(_component_FindCars, {
    class: "findMatchedCars",
    showQuickLinks: false,
    title: "Use the sliders to find cars",
    description: "Slider Tip: Start broadly and refine results on the next page!"
  }, {
    formContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Sliders)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "gradient buttonLarge",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.findMatchedCarsSubmit && _ctx.findMatchedCarsSubmit(...args)))
          }, "Find the Right Car For Me!")
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_SliderPresets)
    ]),
    _: 1
  }))
}