import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";

import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';;

// To be used on app.use(Quasar, { ... })
export default {
	config: {},
	plugins: {
		Dialog,
		Loading
	}
};
