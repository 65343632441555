
import { defineComponent, computed, ref, PropType } from "vue";
import { useRoute } from "vue-router";
import { event } from "vue-gtag";

import UserSvc from "@/assets/js/userSvc";
import Utilities from "@/assets/js/utilities";
import AffiliateFunctions from "@/modules/general/affiliate/affiliateFunctions";
import useSliders from "@/modules/sliders/useSliders";

import CarModel from "../CarModel";

// TODO: give these buttons tooltips?

export default defineComponent({
	name: "SearchLocal",
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		}
	},
	setup(props) {
		const route = useRoute();
		const sliders = useSliders();
		const changeLocationClick = ref(false);

		const edmundsQuoteLink = computed(() => {
			const affiliateInfo = {
				name: "Edmunds.com",
				type: "quote",
				car: props.car,
				path: route.name as string,
				price: sliders.slidersConfig.sliderValues.price
			};

			return AffiliateFunctions.formatAffiliateLink(affiliateInfo);
		});
		const edmundsSearchLink = computed(() => {
			const affiliateInfo = {
				name: "Edmunds.com",
				type: "search",
				car: props.car,
				path: route.name as string,
				price: sliders.slidersConfig.sliderValues.price
			};

			return AffiliateFunctions.formatAffiliateLink(affiliateInfo);
		});
		const showChangeLocation = computed(
			() => false // No current affiliates seem to use the zip code box
			// 	{
			// 	get: () => (zipCode.value.cookie ? true : false),
			// 	set: (newValue) => (changeLocationClick.value = newValue)
			// }
		);
		const showZipCodeBox = computed(
			() => false // No current affiliates seem to use the zip code box
			// {
			// 	get: () => (zipCode.value.cookie ? false : true),
			// 	set: (newValue) => (changeLocationClick.value = newValue)
			// }
		);
		const zipCode = computed(() => {
			const zipCodeCookie = user.value.zipCode;
			const zipCodeText = zipCodeCookie ? zipCodeCookie : "you";

			return {
				text: zipCodeText,
				cookie: zipCodeCookie
			};
		});
		const localAreaText = computed(() => {
			if (!UserSvc.user.zipCode) return "in your local area";

			return "near " + zipCode.value.text;
		});
		const dealerNetworkText = computed(() => {
			if (props.car.saved) return "these dealer networks";

			return "Edmunds.com";
		});
		const user = computed(() => {
			return UserSvc.user;
		});
		const toolTipArticle = computed(() => {
			return Utilities.article(props.car.name);
		});

		// const changeLocation = () => {
		// 	showChangeLocation.value = false;
		// 	showZipCodeBox.value = true;
		// };
		// const setZipCode = () => {
		// 	UserSvc.setZipCode(zipCode.value.cookie);
		// 	showChangeLocation.value = true;
		// 	showZipCodeBox.value = false;
		// };
		const affiliateClick = (eventName: string, category: string) => {
			event(eventName, {
				event_category: category,
				event_label: props.car.name
				// value: 123
			});
		};

		return {
			edmundsQuoteLink,
			edmundsSearchLink,
			showChangeLocation,
			showZipCodeBox,
			zipCode,
			localAreaText,
			dealerNetworkText,
			changeLocationClick,
			Utilities,
			toolTipArticle,
			// changeLocation,
			// setZipCode,
			affiliateClick
		};
	}
});
