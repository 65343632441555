
import { computed, defineComponent, onMounted, ref } from "vue";
import { RouteMeta, useRoute, useRouter } from "vue-router";

import CarsSvc from "@/assets/js/carsSvc";
import useRouteFunctions from "@Core/router/useRouteFunctions";
import Utilities from "@/assets/js/utilities";

import CarModel from "../carDisplay/CarModel";
import SuggestedCarsModel from "../suggestForMe/SuggestedCarsModel";
import SelectMakeModelModel from "@/modules/selectMakeModel/SelectMakeModelModel";
import OpenGalleryModes from "@/modules/gallery/openGallery/openGalleryModesEnum";

import ContentLayout from "LayoutComponents/layouts/ContentLayout.vue";
import DetailSection from "./DetailSection.vue";

import OpenGallery from "../gallery/openGallery/OpenGallery.vue";
import SuggestedCarContainer from "../suggestForMe/suggestedCar/SuggestedCarContainer.vue";
import RadarChart from "./charts/RadarChart.vue";
import SpecsSection from "./specs/SpecsSection.vue";

import OutOfProductionWarning from "GeneralComponents/warnings/outOfProductionWarning/OutOfProductionWarning.vue";
import Ratings from "../carDisplay/ratings/ratings.vue";
import SearchLocal from "../carDisplay/searchLocal/SearchLocal.vue";
import SelectMakeModel from "../selectMakeModel/SelectMakeModel.vue";

// TODO: MSRP is only shown in the radar chart... is that enough?

export default defineComponent({
	name: "DetailCar",
	components: {
		ContentLayout,
		DetailSection,
		OpenGallery,
		SuggestedCarContainer,
		RadarChart,
		SpecsSection,
		Ratings,
		SearchLocal,
		OutOfProductionWarning,
		SelectMakeModel
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const routeFunctions = useRouteFunctions();
		const loading = ref(true);

		const selectedCar = ref<CarModel>();
		const similarCars = ref<CarModel[]>([]);
		const suggestedCars = ref<SuggestedCarsModel>();
		const availableTrims = ref<string[]>([]);
		const selectMakeModel = ref<SelectMakeModelModel>({
			make: "",
			model: "",
			trim: ""
		});

		const fullCarUrlSlug = computed(() => {
			if (!selectedCar.value) return "";

			return routeFunctions.buildFullCarUrlSlug(selectedCar.value);
		});

		const headerBGImageUrl = computed(() => {
			return `url("${selectedCar.value?.images.gallery_path}")`; // css requires double quotes around urls with spaces in the path
		});

		// TODO: make function for generating params (if I don't have one already)
		const trimParams = (trim: string) => {
			if (!selectedCar.value) return {};

			return {
				make: routeFunctions.encodeSlugForUrl(selectedCar.value?.make),
				model: routeFunctions.encodeSlugForUrl(selectedCar.value?.model),
				trim: routeFunctions.encodeSlugForUrl(trim)
			};
		};
		const updateTemplate = () => {
			let meta: RouteMeta = {};

			if (selectedCar.value) {
				meta = routeFunctions.setMetaData({
					title: `Is ${Utilities.article(selectedCar.value.name)} ${selectedCar.value.name} right for me?`,
					description: `Discover if a ${Utilities.article(selectedCar.value.name)} ${selectedCar.value.name} is right for you.`,
					siteImgPath: selectedCar.value.images.gallery_path,
					twitterCardType: "summary_large_image"
				});
			}

			routeFunctions.updateMetaData({ meta }, null, null);
		};
		const makeModelSelected = (props: SelectMakeModelModel) => {
			selectMakeModel.value = props;
		};
		const gotoCar = () => {
			const carforUrl = {
				make: routeFunctions.encodeSlugForUrl(selectMakeModel.value.make as string),
				model: routeFunctions.encodeSlugForUrl(selectMakeModel.value.model as string),
				trim: routeFunctions.encodeSlugForUrl(selectMakeModel.value.trim as string)
			};

			router.push({ name: "DetailCar", params: { make: carforUrl.make, model: carforUrl.model, trim: carforUrl.trim } });
		};

		onMounted(async () => {
			const carName = routeFunctions.buildFullCarUrlSlug(route.params);

			const responseArray = await Promise.all([
				CarsSvc.getCarByName(carName),
				CarsSvc.getSimilarCars(carName),
				CarsSvc.getSuggestedCars(carName),
				CarsSvc.getTrims(route.params.make as string, route.params.model as string)
			]);

			selectedCar.value = responseArray[0];
			similarCars.value = responseArray[1];
			suggestedCars.value = responseArray[2];
			availableTrims.value = responseArray[3];

			updateTemplate();

			loading.value = false;
		});

		return {
			loading,
			selectedCar,
			headerBGImageUrl,
			similarCars,
			suggestedCars,
			availableTrims,
			fullCarUrlSlug,
			trimParams,
			makeModelSelected,
			gotoCar,
			Utilities,
			OpenGalleryModes
		};
	}
});
