import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pageLayout similarCars" }
const _hoisted_2 = {
  id: "inner",
  class: "siteWidth"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdsenseAd = _resolveComponent("AdsenseAd")!
  const _component_FindCars = _resolveComponent("FindCars")!
  const _component_CenteredLayout = _resolveComponent("CenteredLayout")!

  return (_openBlock(), _createBlock(_component_CenteredLayout, { class: "bg-white" }, {
    topAd: _withCtx(() => [
      _createVNode(_component_AdsenseAd, { dataAdSlot: "7780636045" })
    ]),
    bottomAd: _withCtx(() => [
      _createVNode(_component_AdsenseAd, { dataAdSlot: "1734102444" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FindCars, {
            title: "Show me Similar Cars to a...",
            description: "Select a car to start from and view up to 10 similar cars to consider.",
            destination: "SimilarCarsResults",
            actionContent: "View Similar Cars"
          })
        ])
      ])
    ]),
    _: 1
  }))
}