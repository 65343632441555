import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_DrawerToggleFloater = _resolveComponent("DrawerToggleFloater")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createBlock(_component_q_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_Loading, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_q_drawer, {
            modelValue: _ctx.drawerOpen,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerOpen) = $event)),
            "show-if-above": "",
            width: _ctx.drawerWidth,
            breakpoint: _ctx.drawerShownBreakpoint,
            class: "bg-white drawer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_scroll_area, { class: "fit" }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "drawer")
                ]),
                _: 3
              })
            ]),
            _: 3
          }, 8, ["modelValue", "width", "breakpoint"]),
          _createVNode(_component_q_page, {
            id: "sidebarLayoutPageContent",
            class: _normalizeClass(["layoutContainer sidebarLayoutContainer", [_ctx.contentPaddingClasses]])
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "topAd"),
              _renderSlot(_ctx.$slots, "default"),
              (_ctx.drawerToggleFloaterConfig)
                ? (_openBlock(), _createBlock(_component_DrawerToggleFloater, {
                    key: 0,
                    onClick: _ctx.toggleDrawer,
                    label: _ctx.drawerToggleFloaterConfig.label,
                    iconName: _ctx.drawerToggleFloaterConfig.iconName
                  }, null, 8, ["onClick", "label", "iconName"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "bottomAd")
            ]),
            _: 3
          }, 8, ["class"])
        ]),
        _: 3
      }, 8, ["loading"])
    ]),
    _: 3
  }))
}