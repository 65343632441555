import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "titleContainer sliderTitleContainer" }
const _hoisted_2 = { class: "slider" }
const _hoisted_3 = {
  key: 0,
  class: "actionsContainer minusActionsContainer"
}
const _hoisted_4 = { class: "sliderInputContainer" }
const _hoisted_5 = {
  key: 1,
  class: "actionsContainer plusActionsContainer"
}
const _hoisted_6 = { class: "sliderContentContainer rangeSliderContentContainer" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "helpBubble"
}
const _hoisted_10 = { class: "title" }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_slider = _resolveComponent("q-slider")!
  const _component_q_range = _resolveComponent("q-range")!

  return (_openBlock(), _createElementBlock("div", {
    class: "sliderBox",
    "on:presetSelected": _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onPresetSelected && _ctx.onPresetSelected(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["sliderArea", { noIncDec: !_ctx.showIncDec }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", {
          class: _normalizeClass(["title sliderTitle titleBlock", { hasHelpBubble: _ctx.slider.help_bubble }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showHelpBubble = !_ctx.showHelpBubble))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.slider.title), 1)
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showIncDec)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_q_btn, {
                size: "sm",
                flat: "",
                title: `Decrease ${_ctx.slider.title}`,
                icon: "remove",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.decrement())),
                disable: _ctx.sliderValue === _ctx.slider.options.min
              }, null, 8, ["title", "disable"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (typeof _ctx.sliderValue === 'number')
            ? (_openBlock(), _createBlock(_component_q_slider, {
                key: 0,
                modelValue: _ctx.sliderValue,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sliderValue) = $event)),
                onChange: _ctx.sliderChange,
                min: _ctx.slider.options.min,
                max: _ctx.slider.options.max,
                step: _ctx.slider.options.interval,
                markers: _ctx.slider.options.markerInterval,
                "marker-labels": _ctx.markerLabels,
                "marker-labels-class": "text-grey-6 sliderLabel",
                color: "orange",
                "track-size": "9px",
                "thumb-size": "25px",
                "thumb-color": "primary"
              }, null, 8, ["modelValue", "onChange", "min", "max", "step", "markers", "marker-labels"]))
            : (_openBlock(), _createBlock(_component_q_range, {
                key: 1,
                modelValue: _ctx.sliderValue,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sliderValue) = $event)),
                onChange: _ctx.sliderChange,
                min: _ctx.slider.options.min,
                max: _ctx.slider.options.max,
                step: _ctx.slider.options.interval,
                markers: _ctx.slider.options.markerInterval,
                "marker-labels": _ctx.markerLabels,
                "marker-labels-class": "text-grey-6 sliderLabel",
                color: "orange",
                "track-size": "9px",
                "thumb-size": "25px",
                "thumb-color": "primary"
              }, null, 8, ["modelValue", "onChange", "min", "max", "step", "markers", "marker-labels"]))
        ]),
        (_ctx.showIncDec)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_q_btn, {
                size: "sm",
                flat: "",
                title: `Increase ${_ctx.slider.title}`,
                icon: "add",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.increment())),
                disabled: _ctx.sliderValue === _ctx.slider.options.max
              }, null, 8, ["title", "disabled"])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderText, (sliderTextItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "sliderContent",
          key: index
        }, [
          _createElementVNode("h4", {
            class: "title sliderSubtitle",
            innerHTML: sliderTextItem.header
          }, null, 8, _hoisted_7),
          (_ctx.slider.meaning)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: _ctx.TextSvc.formatText(`${_ctx.slider.name}_meaning`, sliderTextItem[_ctx.slider.meaning])
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    (_ctx.slider.help_bubble)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("h6", _hoisted_10, _toDisplayString(_ctx.slider.title) + ":", 1),
          _createElementVNode("div", {
            innerHTML: _ctx.slider.help_bubble
          }, null, 8, _hoisted_11)
        ], 512)), [
          [_vShow, _ctx.showHelpBubble]
        ])
      : _createCommentVNode("", true)
  ], 32))
}