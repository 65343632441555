
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import useEmitter from "@/assets/js/useEmitter";
import useSliders from "@/modules/sliders/useSliders";

import { SliderValuesModel } from "../sliders/slider/SliderModel";

import FindCars from "../findCars/FindCars.vue";
import SliderPresets from "../sliders/presets/SliderPresets.vue";
import Sliders from "../sliders/Sliders.vue";

export default defineComponent({
	name: "FindCarsMatched",
	components: {
		FindCars,
		SliderPresets,
		Sliders
	},
	setup() {
		const emitter = useEmitter();
		const sliders = useSliders();
		const router = useRouter();
		const sliderValues = ref<SliderValuesModel>({
			efficiency: null,
			performance: null,
			quality: null,
			size: {
				max: null,
				min: null
			},
			price: null
		});

		const updateSliderValues = (updatedSliderValues: SliderValuesModel) => {
			sliderValues.value = updatedSliderValues;
		};

		const findMatchedCarsSubmit = () => {
			sliders.saveSlidersConfig({
				sliderValues: sliderValues.value,
				options: {},
				sort: "bestmatch"
			});

			router.push({ name: "Matched-Cars" });
		};

		onMounted(() => {
			emitter.on("slidersUpdated", updateSliderValues);
		});

		onUnmounted(() => {
			emitter.off("slidersUpdated", updateSliderValues);
		});

		return {
			findMatchedCarsSubmit
		};
	}
});
