import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sliders", [$props.mode]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.values.sliders, (slider, index) => {
      return (_openBlock(), _createBlock(_component_Slider, {
        slider: slider,
        key: index
      }, null, 8, ["slider"]))
    }), 128))
  ], 2))
}