import axios from "CarsJSAssets/useAxios";

import UserSvc from "CarsJSAssets/userSvc";
import useRouteFunctions from "@Core/router/useRouteFunctions";
// import useSliders from "@/modules/sliders/useSliders";

import CarModel from "../../modules/carDisplay/CarModel";
import SuggestedCarsModel from "../../modules/suggestForMe/SuggestedCarsModel";
import { ValuesPayload } from "./ValuesModel";

// const sliders = useSliders();
const routeFunctions = useRouteFunctions();

// TODO: I used to call a lot of value "rating" ex: `priceRating` which is now just `price` ... check to make sure I got them all

// TODO: add other sort values to this enum
// export enum SortValues {
// 	BestMatch = "bestmatch"
// }

// Might not need these but it could be useful in the future
// function encodeParams(params) {
// 	Object.keys(params).forEach(function (key, index) {
// 		params[key] = routeFunctions.encodeSlugForUrl(params[key]);
// 	});
// }

function carIsSaved(car: CarModel): boolean {
	return UserSvc.user.savedCars.includes(car.id);
}

function processCar(car: CarModel) {
	car.saved = carIsSaved(car);

	return car;
}

function processCars(cars: CarModel[]): CarModel[] {
	cars.forEach((car: CarModel) => {
		processCar(car);
	});

	return cars;
}

const carsSvc = {
	getCarByName: async (carName: string): Promise<CarModel> => {
		// if (!carName) return "No Car Name";

		const response = await axios.get("/api/cars/" + carName);

		return processCar(response.data);
	},
	getCarById: async (carID: string, params?: any): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carID, { params });

		return processCar(response.data);
	},
	getMultipleCarsById: async (carIDs: string[]): Promise<CarModel[]> => {
		const response = await axios.get("/api/cars/multiple/" + carIDs);

		return processCars(response.data);
	},
	getSimilarCars: async (carName: string): Promise<CarModel[]> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/");

		return processCars(response.data);
	},
	getSuggestedBigger: async (carName: string): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/Bigger");

		return processCar(response.data);
	},
	getSuggestedSmaller: async (carName: string): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/Smaller");

		return processCar(response.data);
	},
	getSuggestedCheaper: async (carName: string): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/Cheaper");

		return processCar(response.data);
	},
	getSuggestedNicer: async (carName: string): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/Nicer");

		return processCar(response.data);
	},
	getSuggestedFaster: async (carName: string): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/Faster");

		return processCar(response.data);
	},
	getSuggestedBetterMPG: async (carName: string): Promise<CarModel> => {
		const response = await axios.get("/api/cars/" + carName + "/Similar/BetterMPG");

		return processCar(response.data);
	},
	getSuggestedCars: async (carName: string): Promise<SuggestedCarsModel> => {
		const responseArray = await Promise.all([
			carsSvc.getSuggestedBigger(carName),
			carsSvc.getSuggestedSmaller(carName),
			carsSvc.getSuggestedCheaper(carName),
			carsSvc.getSuggestedNicer(carName),
			carsSvc.getSuggestedFaster(carName),
			carsSvc.getSuggestedBetterMPG(carName)
		]);

		const suggestedCars: SuggestedCarsModel = {
			bigger: responseArray[0],
			smaller: responseArray[1],
			cheaper: responseArray[2],
			nicer: responseArray[3],
			faster: responseArray[4],
			betterMPG: responseArray[5]
		};

		return suggestedCars;
	},
	getMakes: async (params?: ValuesPayload) => {
		const response = await axios.get("/api/cars/makes/", { params });

		return response.data;
	},
	getModelSeriesList: async (make: string, params: ValuesPayload) => {
		const response = await axios.get("/api/cars/makes/" + routeFunctions.encodeSlugForUrl(make) + "/modelSeriesList/", {
			params
		});

		return response.data;
	},
	getModelsByModelSeries: async (make: string, modelSeries: string, params: ValuesPayload) => {
		const response = await axios.get("/api/cars/makes/" + routeFunctions.encodeSlugForUrl(make) + "/modelSeriesList/" + modelSeries + "/models", {
			params
		});

		return response.data;
	},
	getModels: async (make: string, params?: ValuesPayload) => {
		const response = await axios.get("/api/cars/makes/" + routeFunctions.encodeSlugForUrl(make) + "/models/", {
			params
		});

		return response.data;
	},
	getModelYears: async (make: string, model: string, params: ValuesPayload) => {
		const response = await axios.get(
			"/api/cars/makes/" + routeFunctions.encodeSlugForUrl(make) + "/models/" + routeFunctions.encodeSlugForUrl(model) + "/years",
			{
				params
			}
		);

		return response.data;
	},
	getTrims: async (make: string, model: string, params?: ValuesPayload) => {
		const response = await axios.get(
			"/api/cars/makes/" + routeFunctions.encodeSlugForUrl(make) + "/models/" + routeFunctions.encodeSlugForUrl(model) + "/trims/",
			{
				params
			}
		);

		return response.data;
	},
	getTrimsDetails: async (make: string, model: string, params: ValuesPayload) => {
		const response = await axios.get(
			"/api/cars/makes/" + routeFunctions.encodeSlugForUrl(make) + "/models/" + routeFunctions.encodeSlugForUrl(model) + "/trims/details",
			{
				params
			}
		);

		return response.data;
	},
	getCarOptions: async () => {
		const response = await axios.get("/api/cars/options/");
		return response.data;
	}
};

export default carsSvc;
