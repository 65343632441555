import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "inputContainer quizOptionInputContainer" }
const _hoisted_2 = { class: "labelText" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = {
  key: 0,
  class: "subText"
}
const _hoisted_5 = { class: "inputWrapper" }
const _hoisted_6 = ["type", "name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.mainText), 1),
      (_ctx.subText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.subText), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", _hoisted_5, [
      _withDirectives(_createElementVNode("input", {
        class: "quizStepInput",
        type: _ctx.quizStep.input.type,
        name: _ctx.quizStep.name,
        value: _ctx.quizStepOption,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.quizStepInputValue) = $event))
      }, null, 8, _hoisted_6), [
        [_vModelDynamic, _ctx.quizStepInputValue]
      ])
    ])
  ]))
}