
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, toRef } from "vue";

import CarModel from "../carDisplay/CarModel";

import useScroll from "@/assets/js/useScroll";
import useEmitter from "@/assets/js/useEmitter";
import useCarListSort from "./carListSort/useCarListSort";

import CarDisplay from "../carDisplay/CarDisplay.vue";
import CarListSort from "./carListSort/CarListSort.vue";
import NoMatches from "../matchedCars/noMatches/NoMatches.vue";

export default defineComponent({
	name: "MatchedCars",
	components: {
		CarDisplay,
		CarListSort,
		NoMatches
	},
	props: {
		title: {
			type: String
		},
		description: {
			type: String
		},
		carList: {
			type: Object as PropType<CarModel[]>,
			required: true
		},
		sortOverride: {
			type: String
		},
		inFeedAdId: {
			type: Number,
			required: true
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		const emitter = useEmitter();
		const scroll = useScroll();
		const carListSort = useCarListSort();

		const fullCarList = ref(props.carList);
		const carsShown = ref<CarModel[]>([]);
		const carsPerPage = 10; // TODO: should this be more like 15 or 20?
		const startResults = ref(carsPerPage);
		const carListSortModel = ref<string>(props.sortOverride as string);

		const carsLeft = computed(() => {
			return fullCarList.value.length - startResults.value;
		});

		const loadNextText = computed(() => {
			if (carsLeft.value === 1) return "View Last Car";
			if (carsLeft.value < carsPerPage) return `View Last ${carsLeft.value} Cars`;

			return `View Next ${carsPerPage} Cars`;
		});

		const carListLoading = toRef(props, "loading");

		const scrollMainContainer = () => {
			scroll.scrollContainer({
				offset: 0
			});
		};
		const addMatchedCars = (endPos?: number) => {
			const end = endPos || carsPerPage;

			startResults.value = end;

			carsShown.value = fullCarList.value.slice(0, end);
		};
		const updateSliderSort = (sort: string) => {
			if (!sort) return;

			scrollMainContainer();

			carListSort.setSort(sort, fullCarList.value);

			addMatchedCars();
		};

		const loadMoreCars = () => {
			const add: number = carsLeft.value > carsPerPage ? startResults.value + carsPerPage : startResults.value + carsLeft.value;

			addMatchedCars(add);
		};

		onMounted(() => {
			addMatchedCars();
			emitter.on("sliderSortChanged", updateSliderSort);
		});

		onUnmounted(() => {
			emitter.off("sliderSortChanged", updateSliderSort);
		});

		return {
			fullCarList,
			carsShown,
			carsLeft,
			loadMoreCars,
			loadNextText,
			carListLoading,
			carListSortModel
		};
	}
});
