
import { defineComponent } from "vue";

import CenteredLayout from "LayoutComponents/layouts/CenteredLayout.vue";

import FindCars from "../findCars/FindCars.vue";

export default defineComponent({
	name: "SuggestForMeSelect",
	components: {
		CenteredLayout,
		FindCars
	}
});
