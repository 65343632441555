
import { defineComponent, onMounted, PropType } from "vue";

import { Chart, ChartItem, registerables } from "chart.js"; // TODO: In packages, do I need both chartjs and vue-chartjs?
import ChartDataLabels from "chartjs-plugin-datalabels"; // https://chartjs-plugin-datalabels.netlify.app/guide/options.html
Chart.register(...registerables);

import CarModel, { CarScoreModel, CarScoresModel } from "@/modules/carDisplay/CarModel";
import SliderModel from "@/modules/sliders/slider/SliderModel";

import ValuesSvc from "@/assets/js/valuesSvc";
import Utilities from "@/assets/js/utilities";
import useViewport from "@/assets/js/useViewport";

export default defineComponent({
	name: "RadarChart",
	props: {
		car: {
			type: Object as PropType<CarModel>,
			required: true
		}
	},
	setup(props) {
		const viewport = useViewport();

		function normalizePrice(slider: SliderModel, price: number) {
			const normalizedPrice = Math.abs(price / slider.options.max) * 100;

			if (normalizedPrice > 100) return 100;

			return normalizedPrice;
		}

		function generateLabels() {
			return ValuesSvc.values.sliders.map((slider) => slider.title);
		}
		function generateData() {
			return ValuesSvc.values.sliders.map((slider) => {
				if (slider.name === "price") return normalizePrice(slider, props.car.price);

				const score: CarScoreModel = props.car.scores[slider.name as keyof CarScoresModel] as CarScoreModel;
				return score.cover;
			});
		}

		// Chart initialization needs to be within onMounted or it won't work
		onMounted(() => {
			const ctx: ChartItem = document.getElementById("mychart") as ChartItem;

			const myChart = new Chart(ctx, {
				type: "radar",
				data: {
					labels: generateLabels(),
					datasets: [
						{
							data: generateData(),
							backgroundColor: "rgba(227, 95, 40, .4)",
							borderColor: "rgba(227, 95, 40, .8)",
							borderWidth: 2,
							pointBackgroundColor: "rgb(227, 95, 40)"
						}
					]
				},
				plugins: [ChartDataLabels],
				options: {
					maintainAspectRatio: false, // This forces the chart to accept dimensions set in css
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							callbacks: {
								label: function (context) {
									if (context.label === "Price") return Utilities.currency(props.car.price);

									const score: CarScoreModel = props.car.scores[context.label.toLowerCase() as keyof CarScoresModel] as CarScoreModel;
									return score.text;
								}
							}
						},
						datalabels: {
							labels: {
								title: {
									align: function (context) {
										const index = context.dataIndex;
										const value: number = context.dataset.data[index] as number;
										return value > 80 ? 45 : "end"; // this is pretty good except for when Quality is high
									},
									offset: 10,

									backgroundColor: "#fff", // '#F0991E',
									padding: 5,
									borderRadius: 4,
									borderColor: "rgba(0,0,0,.1)",
									borderWidth: 1,
									color: "#E35F28", // '#fff',
									// @ts-ignore
									font: {
										size: "14px"
									}
								}
							},
							formatter: function (value, context) {
								const labelArray: string[] = context.chart.data.labels as string[];
								let labelText = "";

								for (let i = 0; i < labelArray.length; i++) {
									const label = labelArray[i].toLowerCase();

									const score: CarScoreModel = props.car.scores[label as keyof CarScoresModel] as CarScoreModel;
									if (!score) {
										labelText = Utilities.currency(props.car.price);
										break;
									} else if (score.cover == value) {
										labelText = score.text;
										break;
									}
								}

								return labelText;
							}
						}
					},
					scales: {
						r: {
							angleLines: {
								color: "#e0e0e0"
							},
							grid: {
								color: "#e0e0e0"
							},
							pointLabels: {
								color: "#212629",
								font: {
									size: viewport.smallScreens ? 16 : 20
								}
							},
							ticks: {
								display: false
							}
						}
					},
					scale: {
						min: 0,
						max: 100
					}
				}
			});
		});
	}
});
