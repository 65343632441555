
import { defineComponent } from "vue";

import ContentLayout from "LayoutComponents/layouts/ContentLayout.vue";

import HomeTabs from "./homeTabs/HomeTabs.vue";
import HomeWelcome from "./homeWelcome/HomeWelcome.vue";

export default defineComponent({
	name: "Home",
	components: {
		ContentLayout,
		HomeTabs,
		HomeWelcome
	}
});
