
import { defineComponent, PropType, ref } from "vue";

import QuizStepModel from "./QuizStepModel";
import { AdditionalOptionsSelectedModel } from "@/modules/sliders/additionalOptions/AdditionalOptionsModel";

import AdditionalOptions from "../../sliders/additionalOptions/AdditionalOptions.vue";
import CarListSort from "../../carList/carListSort/CarListSort.vue";

export default defineComponent({
	name: "QuizStepOptions",
	components: {
		AdditionalOptions,
		CarListSort
	},
	props: {
		quizStep: {
			type: Object as PropType<QuizStepModel>,
			required: true
		}
	},
	setup() {
		const quizAdditionalOptionsModel = ref<AdditionalOptionsSelectedModel>({});
		const quizSortModel = ref<string>();

		return { quizAdditionalOptionsModel, quizSortModel };
	}
});
